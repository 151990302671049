import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { getFacilityByUserId, getPayments } from '../../../helpers/api';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import { Empty } from 'antd';
import MUIDataTable from 'mui-datatables';
import PaymentPending from './payment-list/PaymentPending';
import PaymentApproved from './payment-list/PaymentApproved';
import { getYears } from '../../../helpers/dateHelpers';
import { Close, FilterList } from '@mui/icons-material';
import PaymentOption from '../facilities2/facility-invoices/PaymentOption';

const PaymentScreen = withRouter(({ props, history }) => {
  const { userCategory, userPermission, userId } = props;

  const tabs = ['Pending', 'Approved'];
  const years = getYears();
  const statuses = ['PENDING', 'APPROVED'];

  const [tabIndex, setTabIndex] = useState(0);

  const [facilityId, setFacilityId] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [facilityName, setFacilityName] = useState('');
  const [loadingFacilities, setLoadingFacilities] = useState(false);

  const [referenceNo, setReferenceNo] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [status, setStatus] = useState('APPROVED');
  const [year, setYear] = useState(new Date().getFullYear());
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentGateWay, setPaymentGateway] = useState('');

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [addPayment, setAddPayment] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');

  useEffect(() => {
    fetchFacilities();
  }, []);

  const handleChange = (event, newValue) => {
    const index = tabs.indexOf(newValue);
    setTabIndex(index);
  };

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const fetchFacilities = () => {
    setLoadingFacilities(true);
    getFacilityByUserId(userId)
      .then((response) => {
        const responseData = response?.data;
        setFacilities(responseData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingFacilities(false));
  };

  return (
    <Box sx={{ padding: '1rem 1.5rem' }}>
      <Box sx={{ display: 'flex' }}>
        {(userCategory === 'EDM' || userCategory === 'CONSULTANT') && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="button"
            sx={{ textTransform: 'none' }}
            onClick={() => {
              setAddPayment(true);
            }}
          >
            New Payment
          </Button>
        )}
        <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
          <Autocomplete
            fullWidth
            disablePortal
            options={facilities}
            getOptionLabel={(option) => option.facilityName}
            onChange={(e, value) => {
              if (value) {
                setFacilityId(value?.facilityId);
                setFacilityName(value?.facilityName);
              } else {
                setFacilityId('');
                setFacilityName('');
              }
            }}
            size="small"
            sx={{ width: '200px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                //label="Facility"
                placeholder="Filter facilities"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: loadingFacilities && (
                    <CircularProgress size={20} color="primary" />
                  ),
                }}
              />
            )}
          />
          <Select value={year} size="small" onChange={(e) => setYear(e.target.value)}>
            {years?.map((item) => (
              <MenuItem key={item} value={item} selected={item === year}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <br />
      <Tabs
        value={tabs[tabIndex]}
        textColor="secondary"
        indicatorColor="primary"
        onChange={handleChange}
        //variant="fullWidth"
        // TabIndicatorProps={{
        //   sx: {
        //     backgroundColor: "primary.main", // Change the active tab indicator color
        //     borderRadius: "8px", // Rounded indicator
        //     height: "5px", // Adjust thickness
        //   },
        //}}
      >
        {tabs?.map((item, index) => (
          <Tab
            key={index}
            value={item}
            label={
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'capitalize',
                  // backgroundColor: "red",
                  // padding: "0.5rem 1rem",
                  // width:"fit-content",
                  // borderRadius:"10px"
                }}
              >
                {item}
              </Typography>
            }
          />
        ))}
      </Tabs>
      <Divider />
      {tabIndex === 0 && <PaymentPending year={year} facilityId={facilityId} />}
      {tabIndex === 1 && <PaymentApproved year={year} facilityId={facilityId} />}

      {/* {addPayment && (
        <PaymentOption
          open={addPayment}
          onClose={() => setAddPayment(false)}
          invoiceRecord={null}
          onSelectOption={(item) => {
            setSelectedPaymentOption(item);
          }}
        />
      )} */}

      {addPayment && (
        <Dialog open={addPayment} fullWidth maxWidth="sm">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DialogTitle>Select Facility</DialogTitle>
            <Box sx={{ ml: 'auto', mr: '1rem' }}>
              <IconButton onClick={() => setAddPayment(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <DialogContent sx={{ height: '40vh' }}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  options={facilities}
                  getOptionLabel={(option) => option.facilityName}
                  onChange={(e, value) => {
                    if (value) {
                      history.push(`/facilities/invoice/${value?.facilityId}`);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //label="Facility"
                      placeholder="Filter facilities"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: loadingFacilities && (
                          <CircularProgress size={20} color="primary" />
                        ),
                      }}
                    />
                  )}
                />
              </Grid2>
            </Grid2>
            <Box
              sx={{
                border: '0px solid gray',
                height: '70%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
              >{`Select facility to start making payment`}</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
});

export default PaymentScreen;
