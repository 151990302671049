import { Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { getPayments } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
import MUIDataTable from 'mui-datatables';
import { AttachFile, Attachment, Comment, Receipt } from '@mui/icons-material';
import PaymentComment from '../payment-actions/PaymentComment';
import AppPagination from '../../../common/AppPagination';

const PaymentApproved = withRouter(({ props, history, year, facilityId }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(payments)

  const [referenceNo, setReferenceNo] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [status, setStatus] = useState('APPROVED');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentGateWay, setPaymentGateway] = useState('');
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 10;

  const [commentSelected, setCommentSelected] = useState('');
  const [openComment, setOpenComment] = useState(false);

  useEffect(() => {
    fetchPayments();
  }, [facilityId, referenceNo, invoiceId, status, year, paymentMethod, paymentGateWay]);

  const fetchPayments = () => {
    setLoading(true);
    getPayments(
      facilityId,
      referenceNo,
      invoiceId,
      status,
      year,
      paymentMethod,
      paymentGateWay,
      pageNum,
      pageSize
    )
      .then((res) => {
        console.log(res);
        const responseData = res?.result?.data;
        setPayments(responseData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleContentRendering = () => {
    if (loading) {
      return (
        <Box sx={{ padding: '1rem 0' }}>
          <LoaderSkeleton />
        </Box>
      );
    } else {
      if (payments?.length < 1) {
        return (
          <Box sx={{ padding: '3rem' }}>
            <Empty description="Nothing here." />
          </Box>
        );
      } else {
        return renderContent();
      }
    }
  };

  const renderContent = () => {
    const columns = [
      {
        name: 'approvalDate',
        label: 'Paid Date',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paymentDate = payments[index]?.paymentDate;
            return (
              <Typography variant="body2">
                {new Date(paymentDate).toLocaleDateString('en-NG', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Typography>
            );
          },
        },
      },
      {
        name: 'amount',
        label: 'Expected',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const amount = payments[index]?.amount;
            return (
              <Typography variant="body2" sx={{}}>
                {Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount)}
              </Typography>
            );
          },
        },
      },
      {
        name: 'amountPaid',
        label: 'Paid',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paid = payments[index]?.amountPaid;
            const receipt = payments[index]?.paymentReceipt;
            return (
              <Stack direction={'flex'} spacing={0.5} alignItems={'center'}>
                <Typography variant="body2" sx={{}}>
                  {Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(paid)}
                </Typography>
                {receipt && (
                  <Tooltip title="View receipt">
                    <IconButton
                      size="small"
                      onClick={() => {
                        window.open(receipt);
                      }}
                    >
                      <AttachFile fontSize="small" color="success" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
      },
      {
        name: 'facilityName',
        label: 'Facility',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const facility = payments[index]?.facilityName;
            return (
              <Typography
                variant="body2"
                sx={{
                  width: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {facility}
              </Typography>
            );
          },
        },
      },
      {
        name: 'invoiceTitle',
        label: 'Invoice',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const title = payments[index]?.reportTitle;
            return (
              <Typography
                variant="body2"
                sx={
                  {
                    //   width: '150px',
                    //   overflow: 'hidden',
                    //   textOverflow: 'ellipsis',
                    //   display: '-webkit-box',
                    //   WebkitBoxOrient: 'vertical',
                    //   WebkitLineClamp: 1,
                  }
                }
              >
                {title}
              </Typography>
            );
          },
        },
      },
      {
        name: 'verificationStatus',
        label: 'Status',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            let color = '';

            const status = payments[index]?.verificationStatus;
            let displayTxt = status;

            // if (status === 'PENDING') {
            //   color = 'error';
            //   displayTxt = 'Open';
            // }
            if (status === 'PENDING') {
              color = 'warning';
              displayTxt = 'Pending';
            }
            if (status === 'APPROVED') {
              color = 'success';
              displayTxt = 'Approved';
            }
            return <Chip label={displayTxt} color={color} size="medium" sx={{ width: '100px' }} />;
          },
        },
      },
      //   {
      //     name: 'facilityName',
      //     label: 'Facility',
      //     options: {
      //       customHeadLabelRender: (a) => {
      //         return (
      //           <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      //             {a.label}
      //           </Typography>
      //         );
      //       },
      //       customBodyRenderLite: (index) => {
      //         const facility = payments[index]?.facilityName;
      //         return (
      //           <Stack direction="column">
      //             <Typography
      //               variant="body2"
      //             >
      //               {facility}
      //             </Typography>
      //           </Stack>
      //         );
      //       },
      //     },
      //   },
      {
        name: '',
        label: 'Channel',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paymentMethod = payments[index]?.paymentMethod;
            const paymentGateway = payments[index]?.paymentGateway;
            return (
              <Typography variant="body2">
                {paymentMethod} {paymentGateway && <span> | {paymentGateway}</span>}
              </Typography>
            );
          },
        },
      },
      {
        name: 'approver',
        label: 'Approver',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const approver = payments[index]?.approver;
            const comment = payments[index]?.approverComment;
            const approvalDate = payments[index]?.approvalDate;
            return (
              <Stack direction={'row'} spacing={0.5} alignItems={'start'}>
                <Box>
                  <Typography variant="body2">{approver}</Typography>
                  <Typography variant="caption" color="text.disabled">
                    {new Date(approvalDate).toLocaleDateString('en-NG', { dateStyle: 'medium' })}
                  </Typography>
                </Box>

                {comment && (
                  <Tooltip title="Preview Remarks">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setOpenComment(true);
                        setCommentSelected(comment);
                      }}
                    >
                      <Comment color="info" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
      },
    ];

    return (
      <MUIDataTable
        title=""
        data={payments}
        columns={columns}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          setRowProps: (ev, dataIndex) => {
            return {
              style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
            };
          },
        }}
      />
    );
  };
  return (
    <Box>
      {handleContentRendering()}

      <br />
      <AppPagination
        defaultCurrent={1}
        defaultPageSize={pageSize}
        total={total}
        onPageChange={(pageNumber) => {
          setPageNum(pageNumber);
        }}
      />

      {openComment && commentSelected && (
        <PaymentComment
          open={openComment}
          onClose={() => {
            setCommentSelected(false);
            setCommentSelected('');
          }}
          comment={commentSelected}
        />
      )}
    </Box>
  );
});

export default PaymentApproved;
