import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getSamplePointRecord, sendSamplePointRecord } from '../../../../helpers/api';
import { Fragment, useEffect, useState } from 'react';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
import { parseString4 } from '../../../../helpers/utils';
import { openNotification } from '../../../../helpers/notification';
import { Add, Cancel } from '@mui/icons-material';
import { reportStatuses } from '../../../../helpers/appstate';

export default function StandardResultMeasurementInputs({
  report,
  samplePoint,
  //onLoadedResults
}) {
  const initSaveOrUpdate = {
    save: 'save',
    update: 'update',
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [computedParameters, setComputedParameters] = useState([]);
  const [saving, setSaving] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [preview, setPreview] = useState([]);
  const [remainingRuns, setRemainingRuns] = useState(0);
  const [startUpdating, setStartUpdating] = useState(false);
  const [saveOrUpdate, setSaveOrUpdate] = useState(initSaveOrUpdate.save);

  //console.log('startUpdating', startUpdating);
  //console.log('remainingRuns', remainingRuns);
  //console.log('data', data);
  //console.log('saveOrUpdate', saveOrUpdate);
  // console.log('computedParameters', computedParameters);
  //console.log('preview', preview);

  useEffect(() => {
    if (samplePoint?.samplePointId) {
      fetchData();
    }
  }, [samplePoint?.samplePointId]);

  useEffect(() => {
    if (refetch) {
      fetchData();
    }
  }, [refetch]);

  useEffect(() => {
    const initialResult = !data?.results ? [] : data?.results;
    if (initialResult?.length > 0) {
      getInitalPreview(initialResult);
    }
  }, [data]);

  // useEffect(() => {
  //   if (remainingRuns === 0 && startUpdating) setSaveOrUpdate(initSaveOrUpdate.save);
  // }, []);

  const fetchData = () => {
    setLoading(true);

    getSamplePointRecord(samplePoint?.samplePointId, report?.reportId)
      .then((response) => {
        setComputedParameters([]);
        setPreview([]);

        const responseData = response?.result?.data;
        const currentResultCount = !responseData?.results ? 0 : responseData?.results?.length;
        const remRuns = responseData?.minEntry - currentResultCount;
        setData(responseData);
        setRemainingRuns(remRuns);
        setStartUpdating(false);
        setRefetch(false);

        if (remRuns === 0) {
          setSaveOrUpdate(initSaveOrUpdate.update);
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const getInitalPreview = (initialResult) => {
    const pom = [];
    const pom2 = [];

    initialResult?.map((item) => {
      item?.data?.map((item2) => {
        const parameter = data?.parameters?.find((x) => x?.parameterId === item2?.parameterId);
        if (parameter) {
          const dataItem = {
            entryDate: item2?.entryDate,
            parameterId: parameter?.parameterId,
            value: item2?.resultValue,
            unit: parameter?.unit,
          };
          pom.push(dataItem);
        }
      });

      const rowItem = {
        dataRow: item?.dataRow,
        data: pom,
      };
      pom2.push(rowItem);
    });

    setPreview(pom2);
  };

  const handleInputChange = (inputValue, parameterId) => {
    setStartUpdating(true);
    setSaveOrUpdate(initSaveOrUpdate.save);

    const compute = {
      parameterId: parameterId,
      value: Number(inputValue),
      entryDate: new Date().toISOString(),
    };

    setComputedParameters((prev) => {
      const existingParam = prev.find((x) => x.parameterId === parameterId);

      if (existingParam) {
        const index = prev.indexOf(existingParam);
        const updatedParameters = [...prev];
        updatedParameters.splice(index, 1, compute);
        return updatedParameters;
      }

      return [...prev, compute];
    });
  };

  const handleAddToList = () => {
    const pom = [];
    data?.parameters?.map((item) => {
      const parameter = computedParameters?.find((x) => x.parameterId === item?.parameterId);
      if (parameter) {
        const previewItem = {
          entryDate: parameter?.entryDate,
          parameterId: parameter?.parameterId,
          value: parameter?.value,
          unit: item?.unit,
        };
        pom.push(previewItem);
      }
    });
    const rowItem = {
      dataRow: preview?.length + 1,
      data: pom,
    };

    setPreview((prev) => [...prev, rowItem]);
    setComputedParameters([]);
    setRemainingRuns(remainingRuns - 1);
    setStartUpdating(true);
    //saveOrUpdate(initSaveOrUpdate.save);
  };

  const handleSaveInput = () => {
    const modified = preview.map((row) => row.data.map(({ unit, ...rest }) => rest));

    const payload = {
      reportId: report?.reportId,
      facilityId: report?.facilityId,
      samplePointId: samplePoint.samplePointId,
      measurmentId: samplePoint?.measurementId,
      measurements: modified,
    };

    if (!modified?.length) {
      openNotification({
        type: 'error',
        message: `All neccesary data have not been captured`,
      });
    } else {
      setSaving(true);

      sendSamplePointRecord(payload)
        .then((res) => {
          const resultData = res?.result?.status?.desc;
          setRefetch(true);
          setStartUpdating(false);
          //console.log('success', res);
        })
        .catch((err) => {
          const errMsg = err?.data?.result?.status?.desc;
          return openNotification({
            type: 'error',
            message: errMsg ?? 'something went wrong! Please try again.',
          });
        })
        .finally(() => setSaving(false));
    }
  };

  const handleContentRendering = () => {
    if (loading) {
      return <LoaderSkeleton />;
    } else {
      if (!data) {
        return <Empty />;
      } else {
        return renderContent();
      }
    }
  };

  const renderContent = () => {
    return (
      <Box
        sx={{
          padding: '1rem',
          background: '#f9f9f9',
          boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography sx={{ fontWeight: 600 }}>{data?.samplePointName}</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
              {data?.groupName}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <Chip
              label={remainingRuns < 1 ? 'Completed Checks' : `${remainingRuns} Checks remaining`}
              color="info"
              sx={{ fontSize: '0.75rem' }}
            />
          </Box>
        </Box>

        {remainingRuns > 0 && (
          <Box>
            <br />
            <Box
              sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(min(100px, 100%), 1fr))',
                gap: 2,
              }}
            >
              {data?.parameters?.map((item) => {
                return (
                  <TextField
                    key={item?.parameterId}
                    label={parseString4(item?.unit)}
                    fullWidth
                    onBlur={(e) => handleInputChange(e.target.value, item?.parameterId)}
                    sx={{ backgroundColor: '#FFFFFF' }}
                  />
                );
              })}
            </Box>
            <br />
            {data?.reportStatus != reportStatuses.PUBLISHED && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleAddToList}
                  disabled={computedParameters?.length < data?.parameters?.length - 1}
                  startIcon={<Add />}
                  sx={{ textTransform: 'capitalize', width: 'fit-content', borderRadius: '30px' }}
                >
                  Add To List
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setRefetch(true);
                  }}
                  disabled={computedParameters?.length < data?.parameters?.length - 1}
                  startIcon={<Cancel />}
                  sx={{ textTransform: 'capitalize', width: 'fit-content', borderRadius: '30px' }}
                >
                  Cancel
                </Button>
                <br />
              </Box>
            )}
          </Box>
        )}

        {preview?.length > 0 && (
          <Fragment>
            <br />
            {renderPreview()}
            {/* <br /> */}
          </Fragment>
        )}
      </Box>
    );
  };

  const renderPreview = () => {
    const headers = preview[0]?.data;
    return (
      <Box>
        <TableContainer>
          <Table size="small">
            <TableHead sx={{ background: '#699ad0' }}>
              <TableRow>
                <TableCell variant="body2" sx={{ border: '1px solid lightgray', color: 'white' }}>
                  Runs
                </TableCell>
                {headers?.map((headerItem) => {
                  return (
                    <TableCell sx={{ border: '1px solid lightgray', color: 'white' }}>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: headerItem?.unit }}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody TableHead sx={{ background: '#FFFFFF' }}>
              {preview?.map((bodyItem) => {
                return (
                  <TableRow>
                    <TableCell sx={{ border: '1px solid lightgray' }}>
                      <Typography variant="body2" sx={{ padding: '1rem 0' }}>
                        #{bodyItem?.dataRow}
                      </Typography>
                    </TableCell>
                    {bodyItem?.data?.map((bodyItemData) => {
                      return (
                        <TableCell sx={{ border: '1px solid lightgray', textAlign: 'right' }}>
                          <Typography
                            variant="body2"
                            sx={{ padding: '1rem 0' }}
                            dangerouslySetInnerHTML={{ __html: bodyItemData?.value?.toFixed(2) }}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />

        {data?.reportStatus != reportStatuses.PUBLISHED && (
          <Box>
            {remainingRuns < 1 && saveOrUpdate === initSaveOrUpdate.save && startUpdating && (
              <Button
                size="large"
                variant="contained"
                onClick={handleSaveInput}
                startIcon={saving && <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />}
                sx={{ textTransform: 'capitalize' }}
              >
                Save Result
              </Button>
            )}

            {remainingRuns === 0 && saveOrUpdate === initSaveOrUpdate.update && !startUpdating && (
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setSaveOrUpdate(initSaveOrUpdate.update);
                  setStartUpdating(true);
                  setRemainingRuns(data?.minEntry);
                  setPreview([]);
                }}
                sx={{ textTransform: 'none' }}
              >
                Update Result
              </Button>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <br />
      {handleContentRendering()}
    </Box>
  );
}
