import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';

export default function PaymentComment({ open, onClose, comment }) {
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xs" fullWidth>
      <DialogContent>
        <Typography>{comment}</Typography>
      </DialogContent>
    </Dialog>
  );
}
