import { useState } from 'react';
import { Close, PlayArrow } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import PaystackLogo from '../../../../assets/img/paystacklogo.png';
import SquadCoLogo from '../../../../assets/img/squacologo.png';
import OfflinePaymentImg from '../../../../assets/img/offlinepayment.jpeg';
import { paymentOptionsList } from '../../../../helpers/appstate';

export default function PaymentOption({ open, onClose, onSelectOption }) {
  const [selected, setSelected] = useState('');

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <Box sx={{ display: 'flex' }}>
        <DialogTitle>Select Payment option</DialogTitle>
        <Box sx={{ ml: 'auto', padding: '1rem' }}>
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {paymentOptionsList?.map((item) => (
          <Card
            key={item?.gateway}
            sx={{
              mb: '1rem',
              boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)`,
            }}
          >
            <CardActionArea
              onClick={() => {
                onSelectOption(item);
                onClose();
              }}
              sx={{ display: 'flex', justifyContent: 'start', gap: 3 }}
            >
              <CardMedia
                component="img"
                sx={{ width: 100, height: 100 }}
                image={item?.bg}
                alt="Pay with Paystack"
              />
              <Box
                sx={{
                  display: 'flex',
                  //border: '1px solid gray',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Typography>{item?.gateway}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item?.desc}
                  </Typography>
                </Box>
                <Box sx={{ ml: 'auto', padding: '1rem' }}>
                  <Chip
                    label={item?.method}
                    size="small"
                    color={item?.method === "ONLINE" ? "success" : "info"}
                    sx={{ textTransform: 'capitalize', fontSize: '0.6rem' }}
                  />
                </Box>
              </Box>
            </CardActionArea>
          </Card>
        ))}
        {/* <Card sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                Live From Space
              </Typography>
              <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary' }}>
                Mac Miller
              </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
              <IconButton aria-label="previous">
              </IconButton>
              <IconButton aria-label="play/pause">
                <PlayArrow sx={{ height: 38, width: 38 }} />
              </IconButton>
              <IconButton aria-label="next">
              </IconButton>
            </Box>
          </Box>
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image="/static/images/cards/live-from-space.jpg"
            alt="Live from space album cover"
          />
        </Card> */}
      </DialogContent>
    </Dialog>
  );
}
