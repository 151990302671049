import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import FacilityQuartersScreen from '../components/screens/facilities2/FacilityQuartersScreen';

export default function FacilityQuarters(props) {
  return (
    <Layout headerTitle="Quarters">
      <PageContainer id="facility_quarters">
        <FacilityQuartersScreen props={props} />
      </PageContainer>
    </Layout>
  );
}
