import { Add, Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { offlinePaymentChannels } from '../../../../helpers/appstate';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Upload } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL, getInvoices } from '../../../../helpers/api';

export default function FacilityPaymentOffline({ props, onClose, onViewPayments, invoiceId }) {
  const { userCategory, userId, history } = props;
  const facilityId = history.location.pathname?.split('/')[3];
  // const queryParam = history.location?.search;
  // const searchParams = new URLSearchParams(queryParam);
  // const invoiceId = searchParams.get('invoiceId');
  // //console.log('invoiceId', invoiceId);

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  // const initFacility = { facilityId: '', facilityName: '' };
  const initInvoice = { invoiceId: '', invoiceTitle: '' };

  // const [facilityData, setFacilityData] = useState([]);
  // const [facilityLoading, setFacilityLoading] = useState(false);
  // const [facilitySelected, setFacilitySelected] = useState(initFacility);

  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceSelected, setInvoiceSelected] = useState(initInvoice);

  const formik = useFormik({
    initialValues: {
      paymentMethod: offlinePaymentChannels[0]?.value,
      bankName: '',
      bankAccount: '',
      paymentReference: '',
      amountPaid: '',
      paymentDescription: '',
      datePaid: new Date().toISOString().split('T')[0],
      invoiceId: '',
    },
    validationSchema: Yup.object({
      paymentMethod: Yup.string().required('Payment method is required'),
      amountPaid: Yup.string().required('Amount is required'),
    }),
    onSubmit: (values) => handleSubmit2(values),
  });

  //console.log("formik", formik)

  useEffect(() => {
    setInvoiceLoading(true);
    getInvoices('', facilityId, '', '', 1, 10000)
      .then((res) => {
        const responseData = res.result.data;
        const openInvoices = responseData?.filter((x) => x?.invoiceStatus !== 'CLOSED');
        setInvoiceData(openInvoices);

        const findInvoice = responseData?.find((x) => x?.invoiceId === invoiceId);
        if (findInvoice) {
          setInvoiceSelected(findInvoice);
          formik.setValues({ ...formik.values, invoiceId: findInvoice.invoiceId });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setInvoiceLoading(false));
  }, [facilityId, invoiceId]);

  // useEffect(() => {
  //   setFacilityLoading(true);
  //   const url = `${baseURL}/TelerikReports/GetFacilityList/${userId}`;
  //   axios
  //     .get(url, {
  //       method: 'get',
  //     })
  //     .then((res) => {
  //       const responseData = res?.data;
  //       setFacilityData(responseData);

  //       const findFacility = responseData?.find((x) => x?.facilityId === facilityId);
  //       if (findFacility) {
  //         setFacilitySelected(findFacility);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('error', err);
  //     })
  //     .finally(() => setFacilityLoading(false));
  // }, []);

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleSubmit2 = async (values) => {
    console.log(values);
    console.log(fileList);
    setLoading(true);
    try {
      const formData = new FormData();
      console.log('started');
      formData.append('bankName', values.bankName);
      formData.append('bankAccount', values.bankAccountName);
      formData.append('paymentReference', values.paymentReference);
      formData.append('amountPaid', values.amountPaid);
      formData.append('paymentDescription', values.paymentDescription);
      formData.append('invoiceId', invoiceData?.invoiceId);
      formData.append('datePaid', values?.datePaid);
      formData.append('paymentMethod', values.paymentMethod);
      console.log('formdata', formData);

      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        formData.append('receipt', file);
        console.log('formdata', formData.values('receipt'));
      } else {
        console.log('No file selected.');
      }
      const response = await axios.post(`${baseURL}/Payment/ApprovePayment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${getToken()}`,
        },
      });
      consolelog('response', response);
      if (response.data?.result?.status?.code === '00') {
        setTimeout(() => {
          onClose();
        }, 1000);
        return openNotification({
          type: 'success',
          title: 'Success',
          message: 'Confirmed successfully',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DialogTitle variant="body1" sx={{ fontWeight: 600 }}>
          Add Payment
        </DialogTitle>
        <Box
          sx={{
            ml: 'auto',
            mr: '1rem',
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Chip
            label="View Payments"
            color="info"
            variant="outlined"
            onClick={() => {
              onViewPayments(invoiceId);
              //console.log('idiot', invoiceId);
            }}
          />
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {invoiceSelected?.invoiceId !== '' && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" color="info" sx={{ fontWeight: 600 }}>
                {invoiceSelected?.facilityName}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, ml: 'auto' }}>
                <Chip
                  label={`Outstanding: ${Intl.NumberFormat('en-NG', {
                    style: 'currency',
                    currency: 'NGN',
                  }).format(invoiceSelected?.outstandingAmount)}`}
                  color="error"
                />

                <Chip
                  label={`Paid: ${Intl.NumberFormat('en-NG', {
                    style: 'currency',
                    currency: 'NGN',
                  }).format(invoiceSelected?.totalAmountPaid)}`}
                  color="success"
                />
              </Box>
            </Box>
          )}

          {/* <FacilityPaymentList/> */}
          <br />
          <Grid2 container spacing={2}>
            {/* <Grid2 size={{ lg: 6, md: 6, sm: 12, xs: 12 }}>
              <Autocomplete
                fullWidth
                value={facilitySelected}
                options={facilityData}
                getOptionLabel={(option) => option.facilityName}
                onChange={(e, value) => {
                  if (value) {
                    setFacilitySelected(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Faciity"
                    size="medium"
                    required
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: facilityLoading && (
                        <CircularProgress size={20} color="primary" />
                      ),
                    }}
                  />
                )}
              />
            </Grid2> */}
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <Autocomplete
                fullWidth
                value={invoiceSelected}
                options={invoiceData}
                getOptionLabel={(option) => `${option.invoiceTitle}`}
                onChange={(e, value) => {
                  if (value) {
                    setInvoiceSelected(value);
                    formik.setValues({ ...formik.values, invoiceId: value.invoiceId });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Invoice"
                    size="medium"
                    //value={formik.values.invoiceId}
                    required
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: invoiceLoading && (
                        <CircularProgress size={20} color="primary" />
                      ),
                    }}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Payment Method</InputLabel>
                <Select
                  name="paymentMethod"
                  label="Payment Method"
                  value={formik.values.paymentMethod}
                  required
                  onChange={formik.handleChange}
                >
                  {offlinePaymentChannels?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
              <TextField
                name="amountPaid"
                value={formik.values.amountPaid}
                label="Paid Amount"
                placeholder="Paid Amount"
                type="number"
                fullWidth
                //required
                onChange={formik.handleChange}
                error={formik.touched.amountPaid && Boolean(formik.errors.amountPaid)}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>₦</Typography>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
              <TextField
                name="datePaid"
                value={formik.values.datePaid}
                label="Paid Date"
                placeholder="Paid Date"
                fullWidth
                type="date"
                onChange={formik.handleChange}
                error={formik.touched.datePaid && Boolean(formik.errors.datePaid)}
              />
            </Grid2>

            <Grid2 size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
              <TextField
                name="bankName"
                value={formik.values.bankName}
                label="Bank Name"
                placeholder="Bank Name"
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid2>
            <Grid2 size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
              <TextField
                name="bankAccount"
                value={formik.values.bankAccount}
                label="Bank Account Name"
                placeholder="Bank Account Name"
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid2>
            <Grid2 size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
              <TextField
                name="paymentReference"
                value={formik.values.paymentReference}
                label="Reference No."
                placeholder="Reference No."
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid2>
            <Grid2 size={{ lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={() => false} // Prevent default upload behavior
              >
                {fileList.length < 1 && (
                  <div>
                    <Add />
                    <div style={{ marginTop: 8 }}>Upload Receipt</div>
                  </div>
                )}
              </Upload>
            </Grid2>
            <Grid2 size={{ lg: 9, md: 9, sm: 12, xs: 12 }}>
              <TextField
                name="paymentDescription"
                value={formik.values.paymentDescription}
                label="Comment"
                placeholder="Comment"
                fullWidth
                size="small"
                multiline
                rows={4}
                onChange={formik.handleChange}
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions sx={{ padding: '0 1.5rem 1rem 1.5rem' }}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
            sx={{ textTransform: 'none', borderRadius: '30px' }}
          >
            Add Payment
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
