import {
  Avatar,
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { generateFacilityReport } from '../../../helpers/api';
import { openNotification } from '../../../helpers/notification';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import { Empty } from 'antd';
import {
  CardMembership,
  Download,
  KeyboardArrowRight,
  Lock,
  LockOpenOutlined,
  LockOutlined,
  MoreHoriz,
  PieChart,
  Publish,
  Receipt,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import SendInvoice from './facility-quarters/SendInvoice';
import ResendCertificate from './facility-quarters/ResendCertificate';
import PublishResult from './facility-quarters/PublishResult';
import LockUnlockResult from './facility-quarters/LockUnlockResult';
import { reportStatuses, reportTypes, userCats } from '../../../helpers/appstate';
import { getYears } from '../../../helpers/dateHelpers';

const FacilityQuartersScreen = withRouter(({ history, props }) => {
  const { userCategory, userPermission } = props;
  const facilityId = history?.location?.pathname?.split('/')[2];
  const years = getYears();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [row, setRow] = useState();
  const [refetch, setRefetch] = useState(false);

  const [openInvoice, setOpenInvoice] = useState(false);
  const [openCertificate, setOpenCertificate] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [openLockUnlock, setOpenLockUnlock] = useState(false);

  const [anchorOverflowMenuEl, setAnchorOverflowMenuEl] = useState(null);

  const handleOverflowMenuClick = (event) => {
    setAnchorOverflowMenuEl(event.currentTarget);
  };
  const handleOverflowMenuClose = () => {
    setAnchorOverflowMenuEl(null);
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear]);

  useEffect(() => {
    if (refetch) {
      fetchData();
    }
  }, [selectedYear, refetch]);

  const fetchData = () => {
    setLoading(true);
    generateFacilityReport(facilityId, selectedYear)
      .then((response) => {
        const responseData = response?.result?.data;
        setData(responseData);
      })
      .catch((error) => {
        openNotification({
          type: 'error',
          message: error,
        });
      })
      .finally(() => {
        setLoading(false);
        setRefetch(false);
      });
  };

  const handleContentRendering = () => {
    if (loading) {
      return (
        <Box padding="1rem">
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </Box>
      );
    } else {
      if (data?.length < 1) {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Empty />
            </Box>
          </Box>
        );
      } else {
        return renderDisplay();
      }
    }
  };

  const renderDisplay = () => {
    switch (userCategory) {
      case userCats.EDM:
        return renderDisplayForEDM();
      case userCats.CONSULTANT:
        return renderDisplayForConsultant();
    }
  };

  const renderDisplayForEDM = () => {
    return (
      <Paper
        elevation={0}
        sx={{ boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)` }}
      >
        {data?.map((item) => {
          let statusColor = '';
          const status = item?.reportStatus;
          if (status == reportStatuses.DRAFT) statusColor = 'default';
          if (status == reportStatuses.PUBLISHED) statusColor = 'success';

          return (
            <Box key={item?.reportId}>
              <ListItem
                sx={{
                  padding: '1rem 1rem',
                  backgroundColor: item?.reportType === reportTypes.QAQC ? null : '#f5f5f5',
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 'fit-content',
                      padding: '0 1rem',
                      marginRight: '1rem',
                      backgroundColor: item?.reportType === reportTypes.QAQC ? '#ED6C02B3' : null,
                    }}
                  >
                    Quarter {item?.reportQuarter}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="body1">{item?.reportTitle}</Typography>
                  {item?.reportType === reportTypes.QAQC && (
                    <Typography variant="body2" color="warning">
                      Reserved for QAQC
                    </Typography>
                  )}
                </ListItemText>
                <Stack direction="row" spacing={1} alignItems={'center'}>
                  {item?.certificateBase64 && (
                    <Chip
                      label={
                        <a href={item?.certificateBase64} download={'certificate.pdf'}>
                          Certificate
                        </a>
                      }
                      size="small"
                      color="primary"
                      variant="outlined"
                      icon={<Download fontSize="small" sx={{ height: '15px' }} />}
                      sx={{ fontSize: '0.7rem' }}
                      onClick={() => {}}
                    />
                  )}
                  {item?.invoiceUrl && (
                    <Chip
                      label={
                        <a href={item?.invoiceUrl} download={'invoice.pdf'}>
                          Invoice
                        </a>
                      }
                      color="primary"
                      size="small"
                      variant="outlined"
                      icon={<Download sx={{ height: '15px' }} />}
                      sx={{ fontSize: '0.7rem' }}
                      onClick={() => {}}
                    />
                  )}
                  {item?.reportType === reportTypes.QAQC && (
                    <Tooltip
                      title={
                        item?.isVisible ? 'Visible to facilities' : 'Not visible to facilities'
                      }
                    >
                      {item?.isVisible ? (
                        <Visibility fontSize="small" color="disabled" />
                      ) : (
                        <VisibilityOff fontSize="small" color="disabled" />
                      )}
                    </Tooltip>
                  )}

                  <Chip label={status} color={statusColor} sx={{ width: '100px' }} />
                  <Box sx={{ width: '50px', textAlign: 'center' }}>
                    {item?.reportType === reportTypes.QAQC ? renderOverflowMenu(item) : <Lock />}
                  </Box>
                </Stack>
              </ListItem>

              <Divider />
            </Box>
          );
        })}
      </Paper>
    );
  };

  const renderDisplayForConsultant = () => {
    return (
      <Paper
        elevation={0}
        sx={{ boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)` }}
      >
        {data?.map((item) => {
          let statusColor = '';
          const status = item?.reportStatus;
          if (status == reportStatuses.DRAFT) statusColor = 'default';
          if (status == reportStatuses.PUBLISHED) statusColor = 'success';

          return (
            <Box key={item?.reportId}>
              <ListItem
                sx={{
                  padding: '1rem 1rem',
                  backgroundColor: item?.reportType !== reportTypes.QAQC ? null : '#f5f5f5',
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 'fit-content',
                      padding: '0 1rem',
                      marginRight: '1rem',
                      backgroundColor: item?.reportType === reportTypes.QAQC ? '#ED6C02B3' : null,
                    }}
                  >
                    Quarter {item?.reportQuarter}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography>{item?.reportTitle}</Typography>
                  {item?.reportType === reportTypes.QAQC && (
                    <Typography sx={{ fontSize: '0.7rem' }} color="info">
                      Reserved for QAQC
                    </Typography>
                  )}
                </ListItemText>
                <Stack direction="row" spacing={1} alignItems={'center'}>
                  {item?.certificateBase64 && (
                    <Chip
                      label={
                        <a href={item?.certificateBase64} download={'certificate.pdf'}>
                          Certificate
                        </a>
                      }
                      size="small"
                      color="primary"
                      variant="outlined"
                      icon={<Download fontSize="small" sx={{ height: '15px' }} />}
                      sx={{ fontSize: '0.7rem' }}
                      onClick={() => {}}
                    />
                  )}
                  {item?.invoiceUrl && (
                    <Chip
                      label={
                        <a href={item?.invoiceUrl} download={'invoice.pdf'}>
                          Invoice
                        </a>
                      }
                      color="primary"
                      size="small"
                      variant="outlined"
                      icon={<Download sx={{ height: '15px' }} />}
                      sx={{ fontSize: '0.7rem' }}
                      onClick={() => {}}
                    />
                  )}
                  {item?.reportType === reportTypes.QAQC && (
                    <Tooltip
                      title={
                        item?.isVisible ? 'Visible to facilities' : 'Not visible to facilities'
                      }
                    >
                      {item?.isVisible ? (
                        <Visibility fontSize="small" color="disabled" />
                      ) : (
                        <VisibilityOff fontSize="small" color="disabled" />
                      )}
                    </Tooltip>
                  )}
                  <Chip label={status} color={statusColor} sx={{ width: '100px' }} />
                  <Box sx={{ width: '50px', textAlign: 'center' }}>
                    {item?.reportType !== reportTypes.QAQC ||
                    (item?.reportType === reportTypes.QAQC && item?.isVisible) ? (
                      renderOverflowMenu(item)
                    ) : (
                      <Lock />
                    )}
                  </Box>
                </Stack>
              </ListItem>

              <Divider />
            </Box>
          );
        })}
      </Paper>
    );
  };

  const renderOverflowMenu = (item) => {
    return (
      <Box>
        <IconButton
          onClick={(event) => {
            handleOverflowMenuClick(event);
            setRow(item);
          }}
        >
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorOverflowMenuEl}
          open={Boolean(anchorOverflowMenuEl)}
          onClose={handleOverflowMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                boxShadow: 'none',
                filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
                width: '250px',
                border: '1px solid lightgray',
              },
            },
          }}
        >
          <ListItemButton
            onClick={() => {
              handleOverflowMenuClose();
              history.push(
                `/facilities/${row?.facilityId}/quarters/result/${row?.reportId}/${row?.reportQuarter}/${row?.reportYear}`
              );
            }}
          >
            <ListItemIcon>
              <PieChart />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">
                {row?.reportStatus === reportStatuses.PUBLISHED ? 'View Results' : 'Manage Results'}
              </Typography>
            </ListItemText>
            <KeyboardArrowRight />
          </ListItemButton>

          {userCategory === userCats.EDM && (
            <Fragment>
              <Divider />
              <ListItemButton
                disabled={row?.reportStatus === reportStatuses.PUBLISHED}
                onClick={() => {
                  setOpenPublish(true);
                  handleOverflowMenuClose();
                }}
              >
                <ListItemIcon>
                  <Publish />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Publish Result</Typography>
                </ListItemText>
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  setOpenLockUnlock(true);
                  handleOverflowMenuClose();
                }}
              >
                <ListItemIcon>
                  {row?.isVisible ? <LockOutlined /> : <LockOpenOutlined />}
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    {row?.isVisible ? 'Lock Result' : 'Unlock Result'}
                  </Typography>
                </ListItemText>
              </ListItemButton>
              <Divider />

              <ListItemButton
                onClick={() => {
                  handleOverflowMenuClose();
                  setOpenInvoice(true);
                }}
              >
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    {row?.invoiceUrl ? 'Resend Invoice' : 'Send Invoice'}
                  </Typography>
                </ListItemText>
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  handleOverflowMenuClose();
                  setOpenCertificate(true);
                }}
                disabled={row?.reportStatus !== reportStatuses.PUBLISHED}
              >
                <ListItemIcon>
                  <CardMembership />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Resend Certificate</Typography>
                </ListItemText>
              </ListItemButton>
            </Fragment>
          )}
        </Menu>
      </Box>
    );
  };

  return (
    <Box>
      <Box padding="1rem 1.5rem" sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs sx={{ flexGrow: 1 }}>
          <Link to="/facilities">
            <Typography variant="body2" color="info.main">
              Facilities
            </Typography>
          </Link>
          <Typography variant="body2" color="text.primary">
            Quarters
          </Typography>
        </Breadcrumbs>
        <Box>
          <Select
            value={selectedYear}
            size="small"
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {years?.map((year) => (
              <MenuItem key={year} value={year} selected={year === selectedYear}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '1rem',
          margin: { lg: '0 12%', md: '0 12%', sm: '0', xs: '0' },
        }}
      >
        <Box sx={{ padding: '1rem 0' }}>
          <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
            {data[0]?.facility}
          </Typography>
          <Typography variant="body2" color="info">
            {`Select`} <strong>Manage Results</strong>{' '}
            {`from the dropdown menu to enter your
            sampling results`}
          </Typography>
        </Box>
        <br />
        {handleContentRendering()}
      </Box>

      {openPublish && (
        <PublishResult
          open={openPublish}
          title={'Publish Result'}
          onClose={() => {
            setOpenPublish(false);
            setRefetch(true);
          }}
          report={row}
        />
      )}

      {openInvoice && (
        <SendInvoice
          open={openInvoice}
          title={'Send Invoice'}
          onClose={() => {
            setOpenInvoice(false);
            setRefetch(true);
          }}
          report={row}
          history={history}
        />
      )}

      {openCertificate && (
        <ResendCertificate
          open={openCertificate}
          title={'Resend Certificate'}
          report={row}
          onClose={() => {
            setOpenCertificate(false);
            setRefetch(true);
          }}
        />
      )}

      {openLockUnlock && (
        <LockUnlockResult
          open={openLockUnlock}
          onClose={() => {
            setOpenLockUnlock(false);
            setRefetch(true);
          }}
          title={row?.isVisible ? `Lock Resut` : `Unlock Result`}
          report={row}
        />
      )}
    </Box>
  );
});

export default FacilityQuartersScreen;
