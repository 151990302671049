import { Close } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getFacilitiyByIdV2, publishFacilityReport } from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';

export default function PublishResult({ open, title, onClose, report }) {
  const [loading, setLoading] = useState(false);
  const [facility, setFacility] = useState();
  const [facilityLoading, setFacilityLoading] = useState(true);
  const [receipients, setReceipients] = useState([]);

  useEffect(() => {
    fetchFacility();
  }, []);

  useEffect(() => {
    if (facility) {
      getInvoiceReceipients(facility?.facilityConsultant, facility?.facilityPersonel);
    }
  }, [facility]);

  const fetchFacility = () => {
    getFacilitiyByIdV2(report?.facilityId)
      .then((response) => {
        const responseData = response?.result?.data;
        setFacility(responseData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setFacilityLoading(false));
  };

  const handlePublishReport = () => {
    setLoading(true);
    publishFacilityReport(report?.reportId)
      .then((response) => {
        openNotification({
          type: 'success',
          message: 'Action was successfull',
        });

        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((error) => {
        const errMsg = error?.data?.result?.status?.desc;
        openNotification({
          type: 'error',
          message: !errMsg ? 'Something went wrong. Please try again' : errMsg,
        });
      })
      .finally(() => setLoading(false));
  };

  const getInvoiceReceipients = (consultants, personnels) => {
    const invoiceReceipients = [];

    consultants?.map((item) => {
      invoiceReceipients.push(item?.email);
    });

    personnels?.map((item) => {
      const canRecieveInvoice = item?.receiveInvoiceEmail === 'true';
      if (canRecieveInvoice) {
        invoiceReceipients.push(item?.email);
      }
    });

    setReceipients(invoiceReceipients);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '1rem' }}>
        <DialogTitle variant="body2" sx={{ fontWeight: 600 }}>
          {title}
        </DialogTitle>
        <Typography sx={{ marginLeft: 'auto', padding: '0 1rem' }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Typography>
      </Box>
      <DialogContent>
        <Alert severity="info">
          Publishing {report?.reportType} results for {report?.facility}
        </Alert>
        <br />
        <Typography variant="body2" color="warning">
          {`Please check carefully to ensure that all required data were captured`}
        </Typography>
        <br />
        <List subheader={<Typography variant="body2">If this action is completed</Typography>}>
          <ListItem sx={{ padding: '0' }}>
            <ListItemIcon>1.</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2">
                  {`Results can no longer be inputed or updated`}
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ padding: '0' }}>
            <ListItemIcon>2.</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2">
                  {`Invoice will be sent to designated email addresses within the facility for payment`}
                </Typography>
              }
            />
          </ListItem>
        </List>
        <br />
        <Box>
          {facilityLoading ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <CircularProgress size={20} />{' '}
              <Typography variant="body2" color="text.secondary">
                Fetching invoice receipients
              </Typography>
            </Stack>
          ) : (
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ padding: '0 0 0.5rem 0' }}>
                Invoice receipients:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {receipients?.map((x) => (
                  <Chip label={x} />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 1.5rem 2rem 1.5rem' }}>
        <Button
          type="button"
          size="medium"
          variant="contained"
          sx={{ textTransform: 'none', borderRadius: '30px', width: '100px' }}
          onClick={handlePublishReport}
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
}
