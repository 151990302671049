import PaystackLogo from '../assets/img/paystacklogo.png';
import SquadCoLogo from '../assets/img/squacologo.png';
import OfflinePaymentImg from '../assets/img/offlinepayment.jpeg';

export const rolesPerCategory = {
  1: ['COORDINATOR'],
  2: ['SYSTEM ADMINISTRATOR'],
  3: ['FIELD OPERATOR'],
};

export const facilityReportingModals = {
  STANDARD_QUARTERS: 'STANDARD_QUARTERS',
  STANDARD_MEASUREMENTS: 'STANDARD_MEASUREMENTS',
  STANDARD_PARAMETERS: 'STANDARD_PARAMETERS',
};

export const reportTypes = {
  QAQC: 'QAQC',
  NORMAL: 'NORMAL',
};

export const reportStatuses = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const userCategories = ['EDM', 'REGULATOR', 'CONSULTANT'];

export const userCats = {
  EDM: 'EDM',
  REGULATOR: 'REGULATOR',
  CONSULTANT: 'CONSULTANT',
};

export const offlinePaymentChannels = [
  { value: 'REMITA', label: 'REMITA' },
  { value: 'BANK', label: 'BANK' },
];

export const paymentOptionsList = [
  {
    method: 'ONLINE',
    gateway: 'PAYSTACK',
    desc: 'Pay with Paystack',
    color: 'midnightblue',
    bg: PaystackLogo,
  },
  {
    method: 'ONLINE',
    gateway: 'GTPAY',
    desc: 'Pay with Squad',
    color: 'midnightblue',
    bg: SquadCoLogo,
  },
  {
    method: 'OFFLINE',
    gateway: 'OFFLINE',
    desc: 'Pay Offline',
    color: '',
    bg: OfflinePaymentImg,
  },
];
