import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import FacilityInvoiceScreen from '../components/screens/facilities2/FacilityInvoiceScreen';

export default function FacilityInvoice(props) {
  return (
    <Layout headerTitle="Invoice">
      <PageContainer id="facility_invoice">
        <FacilityInvoiceScreen props={props} />
      </PageContainer>
    </Layout>
  );
}
