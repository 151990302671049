import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getFacilityByUserId, getReportsV2 } from '../../../helpers/api';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import { Alert, Empty, Table, Tag } from 'antd';
import { properCase } from '../../../helpers/utils';
import { LockFilled } from '@ant-design/icons';
import AppPagination from '../../common/AppPagination';
import {
  Close,
  Compare,
  FilterAltOutlined,
  Lock,
  LockOpen,
  MoreHoriz,
  MoreVert,
  PieChart,
  Search,
  ViewModule,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import ResultView from './result-view/ResultView';
import ResultComparism from './result-view/ResultComparism';
import ResultLock from './result-lock/ResultLock';
import MUIDataTable from 'mui-datatables';
import { reportStatuses, reportTypes, userCats } from '../../../helpers/appstate';
import { getYears } from '../../../helpers/dateHelpers';
import { withRouter } from 'react-router';

const ResultScreen = withRouter(({ props, history }) => {
  const { userCategory, userPermission, userId } = props;
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];
  const canViewReport = userPerm.includes('view_report');
  const canViewComparativeReport = userPerm.includes('view_comparative_report');
  const canLockUnlockReport = userPerm.includes('lock_report');
  const years = getYears();

  const pageSize = 10;
  //const reportTypes = ['NORMAL', 'QAQC'];
  //const reportStatuses = ['DRAFT', 'PUBLISHED'];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  const [row, setRow] = useState();
  const [openResultView, setOpenResultView] = useState(false);
  const [openResultComparism, setOpenResultComparism] = useState(false);
  const [openResultLocker, setOpenResultLocker] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [searchParam, setSearchParam] = useState('');
  const [search, setSearch] = useState('');
  const [displayYears, setDisplayYears] = useState([]);
  const [anchorOverflowMenuEl, setAnchorOverflowMenuEl] = useState(null);
  const [anchorFilterDrowpdownEl, setAnchorFilterDropdownMenuEl] = useState(null);
  const [loadingFacility, setLoadingFacility] = useState(false);
  const [facilityData, setFacilityData] = useState([]);
  const [reFetchFacility, setReFetchFacility] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState({ facilityId: '', facilityName: '' });
  const [selectedType, setSelectedType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleOverflowMenuClick = (event) => {
    setAnchorOverflowMenuEl(event.currentTarget);
  };
  const handleOverflowMenuClose = () => {
    setAnchorOverflowMenuEl(null);
  };

  const handleFilterDropdownClick = (event) => {
    setAnchorFilterDropdownMenuEl(event.currentTarget);
    setReFetchFacility(true);
  };
  const handleFilterDropdownClose = (event) => {
    setAnchorFilterDropdownMenuEl(null);
  };

  const fethReportData = () => {
    setLoading(true);
    getReportsV2(
      search,
      selectedFacility?.facilityId,
      selectedStatus,
      selectedYear,
      selectedType,
      pageNum,
      pageSize
    )
      .then((response) => {
        const totalCount = response.result.totalCount;
        const reportData = response.result.data;
        setTotal(totalCount);
        setData(reportData);
      })
      .catch((err) => {
        //setPage('error');
      })
      .finally(() => setLoading(false));
  };

  const fetchFacilities = () => {
    setLoadingFacility(true);
    getFacilityByUserId(userId)
      .then((response) => {
        const responseData = response?.data;
        setFacilityData(responseData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingFacility(false));
  };

  const handleRenderDisplay = () => {
    if (loading) {
      return (
        <div style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    } else if (!loading && data.length < 1) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Empty />
          </div>
        </div>
      );
    } else if (!loading && data.length) {
      return renderDisplay();
    }
  };

  const renderDisplay = () => {
    const columns = [
      {
        name: 'reportTitle',
        label: 'Title',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return <Typography variant="body2">{data[index].reportTitle}</Typography>;
          },
        },
      },
      {
        name: 'reportType',
        label: 'Type',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const reportType = data[index].reportType;
            return (
              <Chip
                label={<Typography variant="body2">{reportType}</Typography>}
                variant="filled"
                color={reportType === reportTypes.QAQC ? 'warning' : 'info'}
                size="medium"
                sx={{ width: '100px' }}
              />
            );
          },
        },
      },
      {
        name: 'entryDate',
        label: 'Created Date',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return (
              <Typography variant="body2">
                {new Date(data[index].entryDate).toLocaleDateString('en-NG', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour12: true,
                })}
              </Typography>
            );
          },
        },
      },
      {
        name: 'facility',
        label: 'Facility',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return (
              <Box>
                <Typography variant="body2">{data[index].facility}</Typography>
                <Typography variant="caption" color="textDisabled">
                  Sector: {data[index].sectorName}
                </Typography>
              </Box>
            );
          },
        },
      },
      {
        name: 'stateName',
        label: 'State',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return <Typography variant="body2">{properCase(data[index].stateName)}</Typography>;
          },
        },
      },
      {
        name: 'reportStatus',
        label: 'Status',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            let color = '';
            const reportStatus = data[index].reportStatus;

            if (reportStatus === reportStatuses.DRAFT) color = 'default';
            if (reportStatus === reportStatuses.PUBLISHED) color = 'success';

            return <Chip label={reportStatus} color={color} sx={{ width: '100px' }} />;
          },
        },
      },
      {
        name: '',
        label: '',
        options: {
          customBodyRenderLite: (index) => {
            const record = data[index];
            console.log(record);

            const renderVisibilityIcon = () => {
              if (record.reportType === reportTypes.QAQC) {
                return (
                  <Tooltip
                    title={record?.isVisible ? 'Visible to facility' : 'Not visible to facility'}
                  >
                    {record?.isVisible ? (
                      <Visibility fontSize="small" color="disabled" />
                    ) : (
                      <VisibilityOff fontSize="small" color="disabled" />
                    )}
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title="Visible to facility">
                    <Visibility fontSize="small" color="disabled" />
                  </Tooltip>
                );
              }
            };

            if (
              !record?.isVisible &&
              record?.reportType === reportTypes.QAQC &&
              userCategory === userCats.CONSULTANT
            ) {
              return renderVisibilityIcon();
            } else {
              return (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {renderVisibilityIcon()}
                  <IconButton
                    onClick={(event) => {
                      handleOverflowMenuClick(event);
                      setRow(record);
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorOverflowMenuEl}
                    open={Boolean(anchorOverflowMenuEl)}
                    onClose={handleOverflowMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    slotProps={{
                      paper: {
                        sx: {
                          boxShadow: 'none',
                          filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
                          width: '250px',
                          border: '1px solid lightgray',
                        },
                      },
                    }}
                  >
                    {canViewReport && (
                      <ListItemButton
                        onClick={() => {
                          handleOverflowMenuClose();
                          //setOpenResultView(true);
                          history.push(
                            `results/view/${record?.facilityId}/${record?.reportId}/${record?.reportQuarter}/${record?.reportYear}`
                          );

                          //history.push(`/results/view/${record?.facilityId}`);
                        }}
                      >
                        <ListItemIcon>
                          <PieChart />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2">View Results</Typography>
                        </ListItemText>
                      </ListItemButton>
                    )}

                    {/* {canViewComparativeReport && !isConsultant && (
                      <ListItemButton
                        onClick={() => {
                          handleOverflowMenuClose();
                          setOpenResultComparism(true);
                        }}
                      >
                        <ListItemIcon>
                          <Compare />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2">Compare Result</Typography>
                        </ListItemText>
                      </ListItemButton>
                    )} */}

                    {canLockUnlockReport && row?.reportType === reportTypes.QAQC && (
                      <>
                        <Divider />
                        <ListItemButton
                          onClick={() => {
                            handleOverflowMenuClose();
                            setOpenResultLocker(true);
                          }}
                        >
                          <ListItemIcon>{row?.isVisible ? <Lock /> : <LockOpen />}</ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">
                              {row?.isVisible ? 'Lock Report' : 'Unlock Report'}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </>
                    )}
                  </Menu>
                </Box>
              );
            }
          },
        },
      },
    ];

    return (
      <MUIDataTable
        title=""
        data={data}
        columns={columns}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          setRowProps: (ev, dataIndex) => {
            return {
              style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
            };
          },
        }}
      />
    );
  };

  useEffect(() => {
    fethReportData();
  }, [
    pageNum,
    search,
    selectedFacility?.facilityId ?? '',
    selectedStatus,
    selectedYear,
    selectedType,
  ]);

  useEffect(() => {
    if (refreshPage) {
      fethReportData();
    }
  }, [
    refreshPage,
    pageNum,
    search,
    selectedFacility?.facilityId ?? '',
    selectedStatus,
    selectedYear,
    selectedType,
  ]);

  useEffect(() => {
    if (reFetchFacility) {
      fetchFacilities();
    }
  }, [reFetchFacility]);

  return (
    <Box
      style={{
        padding: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Stack direction="row" spacing={1} alignItems={'center'} sx={{ flexGrow: 1 }}>
          <Select
            value={selectedYear}
            size="small"
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {years?.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>

          <Box>
            <Tooltip title="Filter">
              <IconButton
                onClick={(event) => {
                  handleFilterDropdownClick(event);
                }}
              >
                <FilterAltOutlined />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorFilterDrowpdownEl}
              open={Boolean(anchorFilterDrowpdownEl)}
              onClose={handleFilterDropdownClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              slotProps={{
                paper: {
                  sx: {
                    boxShadow: 'none',
                    filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
                    width: '250px',
                    marginTop: '10px',
                    border: '1px solid lightgray',
                    overflow: 'visible',
                  },
                },
              }}
            >
              <Box sx={{ width: '100%', padding: '1rem' }}>
                <Grid2 container spacing={2}>
                  <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                    <Typography variant="body2" color="textDisabled">
                      Filter by
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={selectedType}
                        label="Type"
                        onChange={(e) => setSelectedType(e.target.value)}
                      >
                        <MenuItem value={reportTypes.NORMAL}>{reportTypes.NORMAL}</MenuItem>
                        <MenuItem value={reportTypes.QAQC}>{reportTypes.QAQC}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid2>
                  <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={selectedStatus}
                        label="Status"
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        <MenuItem value={reportStatuses.DRAFT}>{reportStatuses.DRAFT}</MenuItem>
                        <MenuItem value={reportStatuses.v}>{reportStatuses.PUBLISHED}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid2>
                  <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                    <Box>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        options={facilityData}
                        getOptionLabel={(option) => option.facilityName}
                        onChange={(e, value) => {
                          if (value) setSelectedFacility(value);
                          else setSelectedFacility({ facilityId: '', facilityName: '' });
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Facility"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: props.loading && (
                                <CircularProgress size={20} color="primary" />
                              ),
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Menu>
          </Box>

          {selectedType && (
            <Chip
              label={
                <Stack direction={'row'} spacing={1}>
                  <Typography variant="body2">Type</Typography>:{' '}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {properCase(selectedType)}
                  </Typography>
                </Stack>
              }
              onDelete={() => setSelectedType('')}
            />
          )}

          {selectedStatus && (
            <Chip
              label={
                <Stack direction={'row'} spacing={1}>
                  <Typography variant="body2">Status</Typography>:{' '}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {properCase(selectedStatus)}
                  </Typography>
                </Stack>
              }
              onDelete={() => setSelectedStatus('')}
            />
          )}

          {selectedFacility?.facilityId && (
            <Chip
              label={
                <Stack direction={'row'} spacing={1}>
                  <Typography variant="body2">Facility</Typography>:{' '}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {properCase(selectedFacility?.facilityName)}
                  </Typography>
                </Stack>
              }
              onDelete={() => setSelectedFacility({ facilityId: '', facilityName: '' })}
            />
          )}
        </Stack>

        <TextField
          value={searchParam}
          placeholder="Search title"
          size="small"
          onChange={(e) => setSearchParam(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') setSearch(searchParam);
          }}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton
                  size="small"
                  disabled={!searchParam ? true : false}
                  onClick={() => {
                    setSearch('');
                    setSearchParam('');
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              ),
              startAdornment: (
                <IconButton size="small">
                  <Search fontSize="small" />
                </IconButton>
              ),
            },
          }}
        />
      </Box>

      <br />
      <Box>{handleRenderDisplay()}</Box>
      <br />

      <AppPagination
        defaultCurrent={1}
        defaultPageSize={pageSize}
        total={total}
        onPageChange={(pageNumber) => {
          setPageNum(pageNumber);
        }}
      />

      {openResultView && (
        <ResultView open={openResultView} onClose={() => setOpenResultView(false)} record={row} />
      )}

      {openResultComparism && (
        <ResultComparism
          open={openResultComparism}
          onClose={() => setOpenResultComparism(false)}
          record={row}
          year={selectedYear}
        />
      )}

      {openResultLocker && (
        <ResultLock
          open={openResultLocker}
          onClose={() => setOpenResultLocker(false)}
          record={row}
          onRefreshPage={() => setRefreshPage(true)}
        />
      )}
    </Box>
  );
});

export default ResultScreen;
