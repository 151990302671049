import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import ResultScreen from '../components/screens/result/ResultScreen';

export default function Results(props) {
  return (
    <Layout headerTitle={'results'}>
      <PageContainer id="results">
        <ResultScreen props={props} id="results" />
      </PageContainer>
    </Layout>
  );
}
