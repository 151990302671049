import {
  Box,
  Chip,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { getPayments } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
import MUIDataTable from 'mui-datatables';
import { ApprovalRounded, AttachFile, Attachment, MoreHoriz, Receipt } from '@mui/icons-material';
import AppPagination from '../../../common/AppPagination';

const PaymentPending = withRouter(({ props, history, year, facilityId }) => {
  const [payments, setPayments] = useState([]);
  const [paymentRow, setPaymentRow] = useState([]);
  const [loading, setLoading] = useState(false);

  const [referenceNo, setReferenceNo] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [status, setStatus] = useState('PENDING');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentGateWay, setPaymentGateway] = useState('');
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 10;

  const [menuAnchor, setMenuAnchor] = useState(null);

  useEffect(() => {
    fetchPayments();
  }, [facilityId, referenceNo, invoiceId, status, year, paymentMethod, paymentGateWay]);

  const fetchPayments = () => {
    setLoading(true);
    getPayments(
      facilityId,
      referenceNo,
      invoiceId,
      status,
      year,
      paymentMethod,
      paymentGateWay,
      pageNum,
      pageSize
    )
      .then((res) => {
        console.log(res);
        const responseData = res?.result?.data;
        setPayments(responseData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleContentRendering = () => {
    if (loading) {
      return (
        <Box sx={{ padding: '1rem 0' }}>
          <LoaderSkeleton />
        </Box>
      );
    } else {
      if (payments?.length < 1) {
        return (
          <Box sx={{ padding: '3rem' }}>
            <Empty description="Nothing here." />
          </Box>
        );
      } else {
        return renderContent();
      }
    }
  };

  const renderContent = () => {
    const columns = [
      {
        name: 'approvalDate',
        label: 'Paid Date',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paymentDate = payments[index]?.paymentDate;
            return (
              <Typography variant="body2">
                {new Date(paymentDate).toLocaleDateString('en-NG', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Typography>
            );
          },
        },
      },
      {
        name: 'amount',
        label: 'Expected',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const amount = payments[index]?.amount;
            return (
              <Typography variant="body2" sx={{}}>
                {Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount)}
              </Typography>
            );
          },
        },
      },
      {
        name: 'amountPaid',
        label: 'Paid',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paid = payments[index]?.amountPaid;
            const receipt = payments[index]?.paymentReceipt;
            return (
              <Stack direction={'flex'} spacing={0.5} alignItems={'center'}>
                <Typography variant="body2" sx={{}}>
                  {Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(paid)}
                </Typography>
                {receipt && (
                  <Tooltip title="View receipt">
                    <IconButton
                      size="small"
                      onClick={() => {
                        window.open(receipt);
                      }}
                    >
                      <AttachFile fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
      },
      {
        name: 'facilityName',
        label: 'Facility',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const facility = payments[index]?.facilityName;
            return (
              <Typography
                variant="body2"
                sx={{
                  width: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {facility}
              </Typography>
            );
          },
        },
      },
      {
        name: 'invoiceTitle',
        label: 'Invoice',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const title = payments[index]?.reportTitle;
            return (
              <Typography
                variant="body2"
                sx={{
                //   width: '150px',
                //   overflow: 'hidden',
                //   textOverflow: 'ellipsis',
                //   display: '-webkit-box',
                //   WebkitBoxOrient: 'vertical',
                //   WebkitLineClamp: 1,
                }}
              >
                {title}
              </Typography>
            );
          },
        },
      },
      {
        name: 'verificationStatus',
        label: 'Status',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            let color = '';

            const status = payments[index]?.verificationStatus;
            let displayTxt = status;
            console.log(status)

            // if (status === 'PENDING') {
            //   color = 'error';
            //   displayTxt = 'Open';
            // }
            if (status === 'PENDING') {
              color = 'warning';
              displayTxt = 'Pending';
            }
            if (status === 'SUCCESS') {
              color = 'success';
              displayTxt = 'Closed';
            }
            return <Chip label={displayTxt} color={color} size="medium" sx={{ width: '100px' }} />;
          },
        },
      },
      //   {
      //     name: 'facilityName',
      //     label: 'Facility',
      //     options: {
      //       customHeadLabelRender: (a) => {
      //         return (
      //           <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      //             {a.label}
      //           </Typography>
      //         );
      //       },
      //       customBodyRenderLite: (index) => {
      //         const facility = payments[index]?.facilityName;
      //         return (
      //           <Stack direction="column">
      //             <Typography
      //               variant="body2"
      //             >
      //               {facility}
      //             </Typography>
      //           </Stack>
      //         );
      //       },
      //     },
      //   },
      {
        name: '',
        label: 'Channel',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paymentMethod = payments[index]?.paymentMethod;
            const paymentGateway = payments[index]?.paymentGateway;
            return (
              <Typography variant="body2">
                {paymentMethod} {paymentGateway && <span> | {paymentGateway}</span>}
              </Typography>
            );
          },
        },
      },
      {
        name: '',
        label: '',
        options: {
          customBodyRenderLite: (index) => {
            const rowItem = payments[index];
            return renderMenu(rowItem);
          },
        },
      },
    ];

    return (
      <MUIDataTable
        title=""
        data={payments}
        columns={columns}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          setRowProps: (ev, dataIndex) => {
            return {
              style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
            };
          },
        }}
      />
    );
  };

  const renderMenu = (item) => {
    return (
      <Box>
        <IconButton
          onClick={(event) => {
            handleOpenMenu(event);
            setPaymentRow(item);
          }}
        >
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          //   transformOrigin={{}}
          slotProps={{
            paper: {
              sx: {
                boxShadow: 'none',
                filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
                width: '200px',
                border: '1px solid lightgray',
              },
            },
          }}
        >
          <ListItemButton sx={{ padding: '0.5rme 1rem' }}>
            <ListItemIcon>
              <ApprovalRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{ ml: '-20px' }}
              primary={<Typography variant="body2">Approve Payment</Typography>}
            ></ListItemText>
          </ListItemButton>
        </Menu>
      </Box>
    );
  };

  return (
    <Box>
      {handleContentRendering()}

      <br />
      <AppPagination
        defaultCurrent={1}
        defaultPageSize={pageSize}
        total={total}
        onPageChange={(pageNumber) => {
          setPageNum(pageNumber);
        }}
      />
    </Box>
  );
});

export default PaymentPending;
