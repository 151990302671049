import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  Text,
} from 'recharts';
import * as d3 from 'd3-scale-chromatic';

export default function GraphSimpleBarChart({ data }) {
  console.log("barchart data", data)
  // const COLORS = ['#0C7A2D', '#0088FE', '#D14B19', '#00C49F', '#FFBB28', '#FF8042', '#A2179B'];
  const COLORS = d3.schemeCategory10;

  const renderCustomYAxisTick = ({ x, y, payload }) => (
    <Text x={x} y={y} textAnchor="end" verticalAnchor="middle" fontSize={10}>
      {payload.value}
    </Text>
  );
  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      <BarChart
        width={500}
        height={300}
        data={data}
        layout="vertical"
        margin={{
          top: 30,
          right: 10,
          left: 100,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" tick={renderCustomYAxisTick} />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill="#8884d8">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
