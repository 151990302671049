import { Box, Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getFacilityReport } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
//import StandardResultMeasurementInputs from './StandardResultMeasurementInputs';
import { Circle, PanoramaFishEye } from '@mui/icons-material';
import ResultViewMeasurementsInput from './ResultViewMeasurementsInput';

export default function ResultViewMeasurements({ report }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedSamplePoint, setSelectedSamplePoint] = useState();

  const fetchData = () => {
    setLoading(true);
    getFacilityReport(report?.facilityId, report?.reportQuarter, report?.reportYear)
      .then((response) => {
        const responseData = response?.result?.data;
        const initialSamplePoint = responseData?.samplePoints[0];
        setData(responseData);
        setSelectedSamplePoint(initialSamplePoint);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const getCheckCounts = (spItem) => {
    const checkCounts = [];
    for (let a = 1; a <= spItem?.checkCount; a++) {
      checkCounts.push(a);
    }
    return checkCounts;
  };

  const getResultCounts = (spItem) => {
    const resultCounts = [];
    for (let a = 1; a <= spItem?.resultCount; a++) {
      resultCounts.push(a);
    }
    return resultCounts;
  };

  const handleRendering = () => {
    if (loading) {
      return (
        <Box sx={{ padding: '2rem' }}>
          <LoaderSkeleton />
        </Box>
      );
    }

    if (!loading && !data) {
      return (
        <Box>
          <Empty />
        </Box>
      );
    }

    if (!loading && data) {
      return renderContent2();
    }
  };

  const renderContent2 = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(min(200px, 100%), 1fr))',
          gap: 2,
        }}
      >
        {data?.samplePoints?.map((item, index) => {
          const checkCounts = getCheckCounts(item);
          const resultCounts = getResultCounts(item);

          return (
            <Card
              key={index}
              sx={{ boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)` }}
            >
              <CardActionArea
                onClick={() => setSelectedSamplePoint(item)}
                data-active={
                  item?.samplePointId === selectedSamplePoint?.samplePointId ? '' : undefined
                }
                sx={{
                  height: '100%',
                  '&[data-active]': {
                    backgroundColor: 'action.selected',
                    '&:hover': {
                      backgroundColor: 'action.selectedHover',
                    },
                  },
                }}
              >
                <CardContent sx={{ height: '100%' }}>
                  <Stack direction="row" alignItems={'center'}>
                    <Box>
                      {item?.icon && (
                        <img
                          src={item?.icon}
                          alt="icon"
                          style={{ objectFit: 'contain', width: '30px', height: '30px' }}
                        />
                      )}
                    </Box>
                    <Box sx={{ ml: 'auto' }}>
                      {checkCounts?.map((checkCountitem) => {
                        const availableResults = resultCounts?.find((x) => x === checkCountitem);
                        if (availableResults) {
                          return <Circle color="success" fontSize="small" />;
                        } else {
                          return <PanoramaFishEye color="disabled" fontSize="small" />;
                        }
                      })}
                    </Box>
                  </Stack>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography variant="body1" component="div">
                      {item?.samplePointName}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {item?.measurementName}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box>
      <Box>{handleRendering()}</Box>
      <br />
      <Box>
        <ResultViewMeasurementsInput report={report} samplePoint={selectedSamplePoint}/>
      </Box>
    </Box>
  );
}
