import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Fragment, useState } from 'react';
import { initializePayment } from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';

export function FacilityPaymentOnline({
  props,

  open,
  onClose,
  invoiceRecord,
  paymentOption,
  onViewPayments,
}) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      invoiceId: invoiceRecord?.invoiceId ?? '',
      paymentMethod: paymentOption?.method ?? '',
      paymentGateway: paymentOption?.gateway ?? '',
      paidAmount: 0,
      bankName: '',
      bankAccountName: '',
      paymentReceipt: '',
      remarks: '',
      receiptEmailAddress: '',
      amountToBePaid: '',
    },
    validationSchema: Yup.object({
      receiptEmailAddress: Yup.string().required('Email is required'),
      amountToBePaid: Yup.string().required('Amount is required'),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    //console.log('values', values);
    setLoading(true);

    initializePayment(values)
      .then((res) => {
        console.log(res);
        window.location.replace(res.result.data.authourizedUrl);
      })
      .catch((err) => {
        console.log(err);
        const errMsg = err?.data?.result?.status?.desc;
        return openNotification({
          type: 'error',
          //title: 'Action failed!',
          message: !errMsg ? 'Something went wrong' : errMsg,
        });
      })
      .finally(() => setLoading(false));
  };

  const handleContentRendering = () => {
    if (paymentOption?.method === 'OFFLINE') {
    } else {
      return renderOnlinePaymentForm();
    }
  };

  const renderOnlinePaymentForm = () => {
    return (
      <Box>
        <Box sx={{ padding: '0.5rem 1rem' }}>
          <Alert severity="info">You are paying for {invoiceRecord?.invoiceTitle}</Alert>
          <Box sx={{ display: 'flex', gap: 1, mt: '0.5rem' }}>
            <Chip
              label={`Outstanding: ${Intl.NumberFormat('en-NG', {
                style: 'currency',
                currency: 'NGN',
              }).format(invoiceRecord?.outstandingAmount)}`}
              color="error"
            />
            <Chip
              label={`Total Paid: ${Intl.NumberFormat('en-NG', {
                style: 'currency',
                currency: 'NGN',
              }).format(invoiceRecord?.totalAmountPaid)}`}
              color="success"
            />
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid2 container spacing={2}>
              <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                <Typography variant="body2" color="info" sx={{ padding: '0 0 1rem 0' }}>
                  {`Payment receipt will be sent to the provided email address`}
                </Typography>
              </Grid2>
              <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                <TextField
                  fullWidth
                  name="receiptEmailAddress"
                  label="Email address"
                  placeholder="Enter your email address"
                  value={formik.values.receiptEmailAddress}
                  onChange={formik.handleChange}
                  helperText={formik.errors.receiptEmailAddress}
                  error={
                    formik.touched.receiptEmailAddress && Boolean(formik.errors.receiptEmailAddress)
                  }
                />
              </Grid2>
              <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                <TextField
                  fullWidth
                  name="amountToBePaid"
                  value={formik.values.amountToBePaid}
                  label="Amount"
                  placeholder="Enter payment amount"
                  type="number"
                  helperText={formik.errors.amountToBePaid}
                  onChange={formik.handleChange}
                  error={formik.touched.amountToBePaid && Boolean(formik.errors.amountToBePaid)}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography>₦</Typography>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid2>
            </Grid2>
          </DialogContent>
          <DialogActions sx={{ padding: '0 1.5rem 1rem 1.5rem' }}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
              sx={{ textTransform: 'none', borderRadius: '30px' }}
            >
              Pay with {paymentOption?.gateway}
            </Button>
          </DialogActions>
        </form>
      </Box>
    );
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DialogTitle variant="body1" sx={{ fontWeight: 600 }}>
          Add Payment
        </DialogTitle>
        <Box
          sx={{
            ml: 'auto',
            mr: '1rem',
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Chip
            label="View Payments"
            color="info"
            variant="outlined"
            onClick={() => onViewPayments(invoiceRecord?.invoiceId)}
          />
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      {handleContentRendering()}
    </Dialog>
  );
}
