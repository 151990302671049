import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { toggleQaqcReportVisibilityApi } from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';

export default function ResultLock({ open, onClose, record, onRefreshPage }) {
  const [loading, setLoading] = useState(false);

  const handleLockUnlock = () => {
    setLoading(true);
    const payload = [record?.reportId];
    toggleQaqcReportVisibilityApi(payload)
      .then((response) => {
        onClose();
        onRefreshPage();

        return openNotification({
          type: 'success',
          title: 'Success',
        });
      })
      .catch((error) => {
        const errorMessage = error.data?.result?.status?.desc;
        return openNotification({
          type: 'error',
          title: 'Something went wrong',
          message: `${errorMessage}`,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box display="flex" alignItems={'center'}>
        <Typography flexGrow={1}></Typography>
        <Box padding="1rem">
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {record?.isVisible ? (
          <Box>
            <Alert severity="info" sx={{ fontSize: '1rem' }}>
              You are about to lock this report
            </Alert>
            <br />
            <Typography variant="body2">
              {`If you perform this action, personels of the facility
                  that is attributed to the result will not be able to see it.`}
            </Typography>
            <br />
            <Typography variant="caption">You can unlock it whenever you want</Typography>
          </Box>
        ) : (
          <Box>
            <Alert severity="info" sx={{ fontSize: '1rem' }}>
              You are about to unlock this report
            </Alert>
            <br />
            <Typography variant="body2">{`If you perform this action, personels of the facility
            that is attributed to the result will be able to see it. `}</Typography>
            <br />
            <Typography  variant="caption">You can unlock it whenever you want</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '1.5rem' }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          sx={{ textTransform: 'none' }}
          onClick={handleLockUnlock}
          disabled={loading}
        >
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {loading && <CircularProgress size={20} />}
            <Typography>{record?.isVisible ? 'Yes. Lock it' : 'Yes. Unlock it'}</Typography>
          </Stack>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
