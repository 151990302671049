import {
  Box,
  Breadcrumbs,
  Chip,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getInvoices } from '../../../helpers/api';
import { openNotification } from '../../../helpers/notification';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import { Empty } from 'antd';
import MUIDataTable from 'mui-datatables';
import { Attachment, MoreHoriz, Payment } from '@mui/icons-material';
import PaymentOption from './facility-invoices/PaymentOption';
import { FacilityPaymentOnline } from './facility-invoices/FacilityPaymentOnline';
import FacilityPaymentList from './facility-invoices/FacilityPaymentList';
import FacilityPaymentOffline from './facility-invoices/FacilityPaymentOffline';

const FacilityInvoiceScreen = withRouter(({ history, props }) => {
  // const queryParam = history.location?.search;
  // const searchParams = new URLSearchParams(queryParam);
  // const addPayment = searchParams.get('add');
  // console.log(addPayment);

  const facilityId = history?.location?.pathname?.split('/')[3];
  const { userCategory, userPermission } = props;
  const userCat = userCategory.trim().toLowerCase();
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState('');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [reportId, setReportId] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [row, setRow] = useState('');
  const [selectedInvoiceId, setSelectedInvoiceId] = useState('');
  const [anchorOverflowMenuEl, setAnchorOverflowMenuEl] = useState(null);

  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState();
  const [showPaymentList, setShowPaymentList] = useState(false);
  const [showOfflinePaymentAdd, setShowOfflinePaymentAdd] = useState(false);
  const [showOnlinePaymentAdd, setShowOnlinePaymentAdd] = useState(false);

  // useEffect(() => {
  //   const queryParam = history.location?.search;
  //   const searchParams = new URLSearchParams(queryParam);
  //   const paymentActionQry = searchParams.get('action');
  //   const paymentMethodQry = searchParams.get('method');

  //   if (paymentActionQry === 'view') {
  //     setShowOfflinePaymentAdd(false);
  //     setShowOnlinePaymentAdd(false);
  //     setShowPaymentList(true);
  //   } else if (paymentActionQry === 'add' && paymentMethodQry === 'offline') {
  //     setShowOnlinePaymentAdd(false);
  //     setShowPaymentList(false);
  //     setShowOfflinePaymentAdd(true);
  //   } else if (paymentActionQry === 'add' && paymentMethodQry === 'online') {
  //     setShowPaymentList(false);
  //     setShowOfflinePaymentAdd(false);
  //     setShowOnlinePaymentAdd(true);
  //   }

  //   // if (paymentAction === 'add') {
  //   //   setShowPaymentList(false);
  //   //   setShowOfflinePaymentAdd(true);
  //   // }
  // }, [history.location.search]);

  useEffect(() => {
    fetchInvoices();
  }, [refresh]);

  // useEffect(() => {
  //   if (selectedPaymentOption) {
  //     setShowPaymentOptionAmount(true);
  //   }
  // }, [selectedPaymentOption]);

  const fetchInvoices = () => {
    setLoading(true);
    getInvoices(search, facilityId, status, reportId, 1, 100)
      .then((res) => {
        setLoading(false);
        setData(res.result.data);
        setStatusCode(res.result.status.code);
        setRefresh(false);
      })
      .catch((err) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          message: err.result.status.desc,
        });
      });
  };

  const handleOverflowMenuClick = (event) => {
    setAnchorOverflowMenuEl(event.currentTarget);
  };
  const handleOverflowMenuClose = () => {
    setAnchorOverflowMenuEl(null);
  };

  const handleContentRendering = () => {
    if (loading) {
      return (
        <div style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    } else {
      if (data?.length < 1) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              //border:"1px solid gray",
              height: '60vh',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <Empty />
            </div>
          </div>
        );
      } else {
        return renderDisplay();
      }
    }
  };

  const renderDisplay = () => {
    const columns = [
      {
        name: 'invoiceTitle',
        label: 'Title',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const title = data[index].invoiceTitle;
            const InvoiceNo = data[index].invoiceNo;
            return <Typography variant="body2">{title}</Typography>;
          },
        },
      },
      {
        name: 'invoiceNo',
        label: 'No.',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const InvoiceNo = data[index].invoiceNo;
            return <Typography variant="body2">{InvoiceNo}</Typography>;
          },
        },
      },
      {
        name: 'entryDate',
        label: 'Created',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return (
              <Typography variant="body2">
                {new Date(data[index].entryDate).toLocaleDateString('en-NG', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour12: true,
                })}
              </Typography>
            );
          },
        },
      },
      {
        name: 'amount',
        label: 'Subtotal',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const subtotal = data[index].amount;
            return (
              <Typography variant="body2">
                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(
                  subtotal
                )}
              </Typography>
            );
          },
        },
      },
      {
        name: 'vat',
        label: 'V.A.T',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const vatPercentage = data[index].vat;
            return <Typography variant="body2">{vatPercentage}%</Typography>;
          },
        },
      },
      {
        name: 'totalAmount',
        label: 'Total',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const total = data[index].totalAmount;
            return (
              <Typography variant="body2">
                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(
                  total
                )}
              </Typography>
            );
          },
        },
      },
      {
        name: 'outstandingAmount',
        label: 'Outstanding',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const outstanding = data[index].outstandingAmount;
            return (
              <Typography variant="body2">
                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(
                  outstanding
                )}
              </Typography>
            );
          },
        },
      },
      {
        name: 'invoiceStatus',
        label: 'Status',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const status = data[index].invoiceStatus;
            let color = 'default';
            if (status === 'CLOSED') {
              color = 'success';
            } else if (status === 'OPEN') {
              color = 'error';
            } else {
              color = 'warning';
            }
            return (
              <Chip label={status} color={color} sx={{ width: '100px', textTransform: 'none' }} />
            );
          },
        },
      },
      {
        name: '',
        label: '',
        options: {
          customBodyRenderLite: (index) => {
            const invoice = data[index];

            return (
              <Box>
                <IconButton
                  onClick={(event) => {
                    handleOverflowMenuClick(event);
                    setRow(invoice);
                    setSelectedInvoiceId(invoice?.invoiceId);
                  }}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  anchorEl={anchorOverflowMenuEl}
                  open={Boolean(anchorOverflowMenuEl)}
                  onClose={handleOverflowMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        boxShadow: 'none',
                        filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
                        width: '250px',
                        border: '1px solid lightgray',
                      },
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleOverflowMenuClose()}>
                    <ListItemIcon>
                      <Attachment />
                    </ListItemIcon>
                    <ListItemText>
                      <a href={invoice?.fileUrl} target="_blank" style={{ textDecoration: 'none' }}>
                        <Typography variant="body2">View Invoice</Typography>
                      </a>
                    </ListItemText>
                  </ListItemButton>

                  {userCat === 'edm' && (
                    <ListItemButton
                      onClick={() => {
                        //setShowApprovalForm(true);
                        //setShowPaymentOptions(true);
                        handleOverflowMenuClose();
                        if (invoice?.invoiceStatus === 'CLOSED') {
                          // history.push(
                          //   `/facilities/invoice/${facilityId}?invoiceId=${invoice?.invoiceId}&action=view&method=offline`
                          // );
                          setShowPaymentList(true);
                        } else {
                          // history.push(
                          //   `/facilities/invoice/${facilityId}?invoiceId=${invoice?.invoiceId}&action=add&method=offline`
                          // );
                          setShowOfflinePaymentAdd(true);
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Payment />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">
                          {invoice?.invoiceStatus === 'CLOSED' ? 'View Payment' : 'Add Payment'}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  )}

                  {userCat === 'consultant' && (
                    <ListItemButton
                      onClick={() => {
                        if (invoice?.invoiceStatus === 'CLOSED') {
                          setShowPaymentList(true);
                        } else {
                          setShowPaymentOptions(true);
                        }
                        handleOverflowMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <Payment />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">
                          {invoice?.invoiceStatus === 'CLOSED' ? 'View Payment' : 'Add Payment'}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  )}
                </Menu>
              </Box>
            );
          },
        },
      },
    ];

    return (
      <MUIDataTable
        title=""
        data={data}
        columns={columns}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          setRowProps: (ev, dataIndex) => {
            return {
              style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
            };
          },
        }}
      />
    );
  };
  return (
    <Box>
      <Box
        sx={{
          padding: '1rem',
          background: '#f9f9f9',
          border: '0px solid gray',
          minHeight: '90vh',
        }}
      >
        <Box padding="1rem" sx={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Link to="/facilities">
              <Typography variant="body2" color="info.main">
                Facilities
              </Typography>
            </Link>
            <Typography variant="body2" color="text.primary">
              Invoices - <strong>{data[0]?.facilityName}</strong>
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ padding: '1rem' }}>{handleContentRendering()}</Box>
      </Box>

      {showPaymentOptions && (
        <PaymentOption
          open={showPaymentOptions}
          props={props}
          onClose={() => {
            setShowPaymentOptions(false);
          }}
          onSelectOption={(option) => {
            setSelectedPaymentOption(option);
            if (option?.method === 'OFFLINE') {
              setShowOfflinePaymentAdd(true);
            } else if (option?.method === 'ONLINE') {
              setShowOnlinePaymentAdd(true);
            }
          }}
        />
      )}

      {showOnlinePaymentAdd && (
        <FacilityPaymentOnline
          open={showOnlinePaymentAdd}
          onViewPayments={(paramInvoiceId) => {
            console.log('paramInvoiceId', paramInvoiceId);
            setShowOfflinePaymentAdd(false);
            setShowPaymentList(true);
            setSelectedInvoiceId(paramInvoiceId);
          }}
          onClose={() => {
            setShowOnlinePaymentAdd(false);
          }}
          invoiceRecord={row}
          paymentOption={selectedPaymentOption}
        />
      )}

      {showPaymentList && (
        <FacilityPaymentList
          open={showPaymentList}
          invoiceId={selectedInvoiceId}
          props={props}
          onAddPayments={(paramInvoiceId) => {
            console.log("view invoiceId", paramInvoiceId)
            setSelectedInvoiceId(paramInvoiceId);
            setShowPaymentList(false);
            setShowOfflinePaymentAdd(true);
          }}
          onClose={() => {
            setShowPaymentList(false);
          }}
        />
      )}

      {showOfflinePaymentAdd && (
        <FacilityPaymentOffline
          props={props}
          invoiceId={selectedInvoiceId}
          onViewPayments={(paramInvoiceId) => {
            console.log("add invoiceId", paramInvoiceId)
            setSelectedInvoiceId(paramInvoiceId);
            setShowOfflinePaymentAdd(false);
            setShowPaymentList(true);
          }}
          onClose={() => {
            setShowOfflinePaymentAdd(false);
          }}
        />
      )}
    </Box>
  );
});

export default FacilityInvoiceScreen;
