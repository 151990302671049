import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getSamplePointRecord } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';

export default function ResultViewMeasurementsInput({ report, samplePoint }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [preview, setPreview] = useState([]);

  console.log('preview', preview);

  useEffect(() => {
    if (samplePoint?.samplePointId) {
      fetchData();
    }
  }, [samplePoint?.samplePointId]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const initialResult = !data?.results ? [] : data?.results;
    if (initialResult?.length > 0) {
      getInitalPreview(initialResult);
    }
  }, [data]);

  const fetchData = () => {
    setLoading(true);

    getSamplePointRecord(samplePoint?.samplePointId, report?.reportId)
      .then((response) => {
        setPreview([]);
        const responseData = response?.result?.data;
        const currentResultCount = !responseData?.results ? 0 : responseData?.results?.length;
        const remRuns = responseData?.minEntry - currentResultCount;
        setData(responseData);
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const getInitalPreview = (initialResult) => {
    const pom = [];
    const pom2 = [];

    initialResult?.map((item) => {
      item?.data?.map((item2) => {
        const parameter = data?.parameters?.find((x) => x?.parameterId === item2?.parameterId);
        if (parameter) {
          const dataItem = {
            entryDate: item2?.entryDate,
            parameterId: parameter?.parameterId,
            value: item2?.resultValue,
            unit: parameter?.unit,
          };
          pom.push(dataItem);
        }
      });

      const rowItem = {
        dataRow: item?.dataRow,
        data: pom,
      };
      pom2.push(rowItem);
    });

    setPreview(pom2);
  };

  const handleContentRendering = () => {
    if (loading) {
      return <LoaderSkeleton />;
    } else {
      if (!data) {
        return <Empty />;
      } else {
        return renderContent();
      }
    }
  };

  const renderContent = () => {
    const headers = preview[0]?.data;
    return (
      <Box
        sx={{
          padding: '1rem',
          background: '#f9f9f9',
          boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography sx={{ fontWeight: 600 }}>{data?.samplePointName}</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
              {data?.groupName}
            </Typography>
          </Box>
        </Box>
        <br />
        {preview?.length < 1 ? (
          <Box>
            <Empty />;
          </Box>
        ) : (
          <TableContainer>
            <Table size="small">
              <TableHead sx={{ background: '#699ad0' }}>
                <TableRow>
                  <TableCell variant="body2" sx={{ border: '1px solid lightgray', color: 'white' }}>
                    Runs
                  </TableCell>
                  {headers?.map((headerItem) => {
                    return (
                      <TableCell sx={{ border: '1px solid lightgray', color: 'white' }}>
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{ __html: headerItem?.unit }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody TableHead sx={{ background: '#FFFFFF' }}>
                {preview?.map((bodyItem) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ border: '1px solid lightgray' }}>
                        <Typography variant="body2" sx={{ padding: '1rem 0' }}>
                          #{bodyItem?.dataRow}
                        </Typography>
                      </TableCell>
                      {bodyItem?.data?.map((bodyItemData) => {
                        return (
                          <TableCell sx={{ border: '1px solid lightgray', textAlign: 'right' }}>
                            <Typography
                              variant="body2"
                              sx={{ padding: '1rem 0' }}
                              dangerouslySetInnerHTML={{ __html: bodyItemData?.value?.toFixed(2) }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    );
  };
  return (
    <Box>
      <br />
      {handleContentRendering()}
    </Box>
  );
}
