import { Tabs } from 'antd';
import FacilityQuarterlyReport from './reports/FacilityQuarterlyReport';
import FacilityAnalyticsReport from './reports/FacilityAnalytics';
import EmailLogs from './activities/EmailLogs';
import AuditLogs from './activities/AuditLogs';
import PaymentReports from './payments/PaymentReport';
import { Box } from '@mui/material';
import AnalyticsReport from './reports/AnalyticsReport';

export default function ReportingScreen(props) {
  const permissions = props.props.userPermission?.map((i) => {
    return i.toLowerCase();
  });

  console.log(permissions);

  const canViewActivityReports = permissions.includes('activity_reports');
  const canViewMonitoringReports = permissions.includes('monitoring_reports');
  const canViewPaymentReports = permissions.includes('payment_reports');

  const items1 = [
    {
      key: '1',
      label: 'Quarterly Report',
      children: <FacilityQuarterlyReport props={props} />,
    },
    {
      key: '2',
      label: 'Analytics Report',
      children: <FacilityAnalyticsReport props={props} />,
    },
    // {
    //   key: '3',
    //   label: 'Analytics Report (test)',
    //   children: <AnalyticsReport props={props} />,
    // },
  ];

  const items2 = [
    {
      key: '1',
      label: 'QA/QC Payments',
      children: <PaymentReports />,
    },
  ];

  const items3 = [
    {
      key: '1',
      label: 'Audit Log',
      children: <AuditLogs />,
    },
    {
      key: '2',
      label: 'Email log',
      children: <EmailLogs />,
    },
  ];

  const menus = [
    {
      key: '1',
      label: 'Monitoring Reports',
      children: <Tabs tabPosition="left" defaultActiveKey="1" items={items1} />,
      visible: canViewMonitoringReports,
    },
    {
      key: '2',
      label: 'Payment Reports',
      children: <Tabs tabPosition="left" defaultActiveKey="1" items={items2} />,
      visible: canViewPaymentReports,
    },
    {
      key: '3',
      label: 'Activity Reports',
      children: <Tabs tabPosition="left" defaultActiveKey="1" items={items3} />,
      visible: canViewActivityReports,
    },
  ];

  const topTabs = menus
    .filter((i) => i.visible) // Ensure only visible items are included
    .map((i) => ({
      key: i.key,
      label: i.label,
      children: i.children,
    }));

  console.log(topTabs);

  return (
    <Box component={'section'} sx={{ padding: '1rem 1.5rem' }}>
      <Tabs tabPosition="top" defaultActiveKey="1" items={topTabs} centered />
    </Box>
  );
}
