import React, { useState, useEffect } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { LinkOff } from '@mui/icons-material';

export default function Facilities({ history, props }) {
  return (
    <Box sx={{height:"100%", padding:"5rem 5rem 0 5rem"}}>
      <Box>
        <LinkOff sx={{ fontSize: '7rem' }} color="disabled" />
      </Box>
      <Typography variant="h6" color="textSecondary">
        We could not find the resource you are looking for
      </Typography>
      <br />
      <Button
        variant="contained"
        size="large"
        sx={{ textTransform: 'none' }}
        onClick={() => {
          window.location.href = '/#/';
        }}
      >
        Return to home
      </Button>
    </Box>
  );
}
