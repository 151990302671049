import { Close } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { generateInvoice, getFacilitiyByIdV2, resendInvoice } from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';

export default function SendInvoice({ open, title, onClose, report, history }) {
  const [sending, setSending] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [facility, setFacility] = useState();
  const [facilityLoading, setFacilityLoading] = useState(true);
  const [receipients, setReceipients] = useState([]);

  // console.log('facility', facility);
  // console.log('report', report);
  // console.log('receipients', receipients);

  useEffect(() => {
    fetchFacility();
  }, []);

  useEffect(() => {
    if (facility) {
      getInvoiceReceipients(facility?.facilityConsultant, facility?.facilityPersonel);
    }
  }, [facility]);

  const fetchFacility = () => {
    getFacilitiyByIdV2(report?.facilityId)
      .then((response) => {
        const responseData = response?.result?.data;
        setFacility(responseData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setFacilityLoading(false));
  };

  const getInvoiceReceipients = (consultants, personnels) => {
    const invoiceReceipients = [];

    consultants?.map((item) => {
      invoiceReceipients.push(item?.email);
    });

    personnels?.map((item) => {
      const canRecieveInvoice = item?.receiveInvoiceEmail === 'true';
      if (canRecieveInvoice) {
        invoiceReceipients.push(item?.email);
      }
    });

    setReceipients(invoiceReceipients);
  };

  const handleSendInvoice = () => {
    setSending(true);

    resendInvoice(report?.reportId)
      .then((response) => {
        openNotification({
          type: 'success',
          message: 'Invoice sent successfully',
        });

        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((error) => {
        //console.log(error);
        const errMsg = error?.data?.result?.status?.desc;
        openNotification({
          type: 'error',
          //title: 'Error',
          message: !errMsg ? 'Something went wrong. Please try again' : errMsg,
        });
      })
      .finally(() => setSending(false));
  };

  const handlePreviewInvoie = () => {
    if (report?.invoiceUrl) {
      window.open(report?.invoiceUrl, '_blank');
    } else {
      setGenerating(true);
      generateInvoice(report?.reportId)
        .then((response) => {
          console.log(response);
          window.open(report?.invoiceUrl, '_blank');
        })
        .catch((error) => {
          //console.log(error);
          const errMsg = error?.data?.result?.status?.desc;
          openNotification({
            type: 'error',
            //title: 'Error',
            message: !errMsg ? 'Something went wrong. Please try again' : errMsg,
          });
        })
        .finally(() => setGenerating(false));
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '1rem' }}>
        <DialogTitle variant="body1" sx={{ fontWeight: 600 }}>
          {title}
        </DialogTitle>
        <Typography sx={{ marginLeft: 'auto', padding: '0 1rem' }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Typography>
      </Box>
      <DialogContent>
        <Box>
          {!report?.invoiceUrl ? (
            <Alert severity="info">
              Sending invoice for {report?.reportTitle} to {report?.facility}
            </Alert>
          ) : (
            <Alert severity="info">
              Resending invoice for {report?.reportTitle} to {report?.facility}
            </Alert>
          )}
        </Box>
        <br />
        <Typography variant="body2">
          {`The invoice will be sent to the designated email addresses within ${report?.facility}. 
          You can preview invoice to ensure accuracy before sending`}
        </Typography>
        <br />
        <Box>
          {facilityLoading ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <CircularProgress size={20} />{' '}
              <Typography variant="body2" color="text.secondary">
                Fetching invoice receipients
              </Typography>
            </Stack>
          ) : (
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ padding: '0 0 0.5rem 0' }}>
                Invoice receipients:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {receipients?.map((x) => (
                  <Chip label={x} />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 1.5rem 2rem 1.5rem' }}>
        <Button
          type="button"
          size="medium"
          variant="outlined"
          sx={{ textTransform: 'none', borderRadius: '30px', width: '100px' }}
          onClick={handlePreviewInvoie}
          startIcon={generating && <CircularProgress size={20} />}
          disabled={generating}
        >
          Preview
        </Button>
        <Button
          type="button"
          size="medium"
          variant="contained"
          sx={{ textTransform: 'none', borderRadius: '30px', width: '100px' }}
          onClick={handleSendInvoice}
          startIcon={sending && <CircularProgress size={20} />}
          disabled={sending}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
