import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { getFacilitiyV2 } from '../../../helpers/api';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import { Empty } from 'antd';
import MUIDataTable from 'mui-datatables';
import AppPagination from '../../common/AppPagination';
import { properCase } from '../../../helpers/utils';
import {
  Add,
  CardMembership,
  Close,
  Delete,
  Edit,
  EventSeat,
  FilterAltOutlined,
  KeyboardArrowRight,
  MoreHoriz,
  Payment,
  PieChart,
  Search,
} from '@mui/icons-material';
import FacilityReportQuarters from './facility-result-quarters/FacilityReportQuarters';
import { withRouter } from 'react-router';
import { userCats } from '../../../helpers/appstate';

const FacilityScreen = withRouter(({ props, history }) => {
  const { userCategory, userPermission } = props;
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  const canCreateStandardReport = userPerm.includes('record_report');
  const canCreateAdhocReport = userPerm.includes('record_report');
  const canLockFacilityReport = userPerm.includes('schedule_quarter');
  const canViewFacilityReport = userPerm.includes('view_report');
  const canUpdateFacility = userPerm.includes('update_facility');
  const canApproveFacility = userPerm.includes('approve_facility');
  const canApprovePayment = userPerm.includes('approve_payment');
  const canCreateFacility = userPerm.includes('create_facility');

  const categories = ['MINI', 'SMALL', 'MEDUIM', 'LARGE'];
  const statuses = ['ACTIVE', 'INACTIVE'];

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [row, setRow] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 10;
  const [search, setSearch] = useState('');
  const [searchParam, setSearchParam] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [anchorOverflowMenuEl, setAnchorOverflowMenuEl] = useState(null);
  const [anchorFilterDrowpdownEl, setAnchorFilterDropdownMenuEl] = useState(null);

  const [dialogQuarters, setDialogQuarters] = useState(false);
  

  const handleOverflowMenuClick = (event) => {
    setAnchorOverflowMenuEl(event.currentTarget);
  };
  const handleOverflowMenuClose = () => {
    setAnchorOverflowMenuEl(null);
  };

  const handleFilterDropdownClick = (event) => {
    setAnchorFilterDropdownMenuEl(event.currentTarget);
    //setReFetchFacility(true);
  };
  const handleFilterDropdownClose = (event) => {
    setAnchorFilterDropdownMenuEl(null);
  };

  const fetchData = () => {
    setLoading(true);
    getFacilitiyV2(search, selectedStatus, '', '', '', '', '', pageNum, pageSize)
      .then((response) => {
        //setLoading(false);

        const totalCount = response.result.totalCount;
        const facilityData = response.result.data;
        setTotal(totalCount);
        setData(facilityData);
      })
      .catch((error) => {
        //setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [search, pageNum, total, selectedStatus]);

  const handleRenderDisplay = () => {
    if (loading) {
      return (
        <div style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    } else if (!loading && data.length < 1) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //border:"1px solid gray",
            height: '60vh',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Empty />
          </div>
        </div>
      );
    } else if (!loading && data.length > 0) {
      return renderDisplay();
    }
  };

  const renderDisplay = () => {
    const columns = [
      {
        name: 'facilityName',
        label: 'Name',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const samplePointCount = data[index]?.samplePointCount;
            const facilityName = data[index]?.facilityName;
            return (
              <Stack
                direction="column"
                sx={{
                  width: '250px',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    width: '250px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                >
                  {facilityName}
                </Typography>
                <Typography variant="caption" color="textDisabled">
                  {`${samplePointCount} ${samplePointCount > 1 ? 'Sample Points' : 'Sample Point'}`}
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        name: '',
        label: 'Category',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const category = data[index]?.facilityCategoryName;
            let color;

            if (category === 'MINI') color = 'primary';
            if (category === 'SMALL') color = 'success';
            if (category === 'MEDUIM') color = 'warning';
            if (category === 'LARGE') color = 'error';

            return (
              <Chip
                label={<Typography variant="body2">{properCase(category)}</Typography>}
                variant="filled"
                color={color}
                sx={{ width: '80px' }}
              />
            );
          },
        },
      },
      {
        name: 'stateName',
        label: 'State',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return <Typography variant="body2">{data[index]?.stateName}</Typography>;
          },
        },
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return (
              <Tooltip title={data[index]?.address}>
                <Typography
                  variant="body2"
                  sx={{
                    width: '250px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                >
                  {data[index]?.address}
                </Typography>
              </Tooltip>
            );
          },
        },
      },

      {
        name: 'entryDate',
        label: 'Created Date',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return (
              <Typography variant="body2">
                {new Date(data[index].entryDate).toLocaleDateString('en-NG', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour12: true,
                })}
              </Typography>
            );
          },
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const status = data[index]?.status;
            let color;

            if (status === 'ACTIVE') color = 'success';
            if (status === 'INACTIVE') color = 'warning';

            return (
              <Chip
                label={<Typography variant="body2">{properCase(status)}</Typography>}
                variant="outlined"
                color={color}
                sx={{ width: '70px' }}
              />
            );
          },
        },
      },
      {
        name: '',
        label: '',
        options: {
          customBodyRenderLite: (index) => {
            const record = data[index];

            return (
              <Box>
                <IconButton
                  onClick={(event) => {
                    handleOverflowMenuClick(event);
                    setRow(record);
                  }}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  anchorEl={anchorOverflowMenuEl}
                  open={Boolean(anchorOverflowMenuEl)}
                  onClose={handleOverflowMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        boxShadow: 'none',
                        filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
                        width: '250px',
                        border: '1px solid lightgray',
                      },
                    },
                  }}
                >
                  {canCreateStandardReport && (
                    <Box>
                      <ListItemButton
                        onClick={() => {
                          handleOverflowMenuClose();
                          history.push(`/facilities/${row?.facilityId}/quarters`);
                        }}
                      >
                        <ListItemIcon>
                          <PieChart />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2">Standard Result</Typography>
                        </ListItemText>
                        <KeyboardArrowRight />
                      </ListItemButton>

                      <Divider />
                    </Box>
                  )}

                  {canLockFacilityReport && (
                    <ListItemButton
                      onClick={() => {
                        handleOverflowMenuClose();
                        setDialogQuarters(true);
                      }}
                    >
                      <ListItemIcon>
                        <EventSeat />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">Reserve Quarter</Typography>
                      </ListItemText>
                    </ListItemButton>
                  )}

                  {canViewFacilityReport && (
                    <ListItemButton
                      onClick={() => {
                        handleOverflowMenuClose();
                        //setOpenResultView(true);
                      }}
                    >
                      <ListItemIcon>
                        <CardMembership />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">EIA Certificate</Typography>
                      </ListItemText>
                    </ListItemButton>
                  )}

                  <ListItemButton
                    onClick={() => {
                      handleOverflowMenuClose();
                      //setDialogInvoice(true);
                      history.push(`/facilities/invoice/${row?.facilityId}`);
                    }}
                  >
                    <ListItemIcon>
                      <Payment />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body2">Invoices & Payments</Typography>
                    </ListItemText>
                    <KeyboardArrowRight />
                  </ListItemButton>

                  {userCategory === userCats.EDM && canUpdateFacility && (
                    <Box>
                      <Divider />

                      <ListItemButton
                        onClick={() => {
                          handleOverflowMenuClose();
                          history.push(`/facilities/edit/${row?.facilityId}`);
                        }}
                      >
                        <ListItemIcon>
                          <Edit color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2">Edit Facility</Typography>
                        </ListItemText>
                        <KeyboardArrowRight />
                      </ListItemButton>

                      <ListItemButton
                        onClick={() => {
                          handleOverflowMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <Delete color="error" />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2">Delete Facility</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </Box>
                  )}
                </Menu>
              </Box>
            );
          },
        },
      },
    ];

    return (
      <MUIDataTable
        title=""
        data={data}
        columns={columns}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          setRowProps: (ev, dataIndex) => {
            return {
              style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
            };
          },
        }}
      />
    );
  };

  return (
    <Box>
      <Box
        style={{
          padding: '1rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          {userCategory === userCats.EDM && canCreateFacility && (
            <Button
              variant="contained"
              size="large"
              sx={{ textTransform: 'None' }}
              startIcon={<Add />}
              onClick={() => history.push('/facilities/create')}
            >
              Create Facility
            </Button>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            //justifyContent: 'end',
          }}
        >
          {selectedStatus && (
            <Chip
              label={
                <Stack direction={'row'} spacing={1}>
                  <Typography variant="body2">Status</Typography>:{' '}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {properCase(selectedStatus)}
                  </Typography>
                </Stack>
              }
              onDelete={() => setSelectedStatus('')}
            />
          )}

          <Box>
            <Tooltip title="Filter">
              <IconButton
                onClick={(event) => {
                  handleFilterDropdownClick(event);
                }}
              >
                <FilterAltOutlined />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorFilterDrowpdownEl}
              open={Boolean(anchorFilterDrowpdownEl)}
              onClose={handleFilterDropdownClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              slotProps={{
                paper: {
                  sx: {
                    boxShadow: 'none',
                    filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
                    width: '250px',
                    marginTop: '10px',
                    border: '1px solid lightgray',
                    overflow: 'visible',
                  },
                },
              }}
            >
              <Box sx={{ width: '100%', padding: '1rem' }}>
                <Grid2 container spacing={2}>
                  <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                    <Typography variant="body2" color="textDisabled">
                      Filter by
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={selectedStatus}
                        label="Type"
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        {statuses?.map((item) => (
                          <MenuItem value={item}>{properCase(item)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid2>
                </Grid2>
              </Box>
            </Menu>
          </Box>
          <TextField
            value={searchParam}
            placeholder="Search facility"
            size="small"
            onChange={(e) => setSearchParam(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') setSearch(searchParam);
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton
                    size="small"
                    disabled={!searchParam ? true : false}
                    onClick={() => {
                      setSearch('');
                      setSearchParam('');
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                ),
                startAdornment: (
                  <IconButton size="small">
                    <Search fontSize="small" />
                  </IconButton>
                ),
              },
            }}
          />
        </Box>
      </Box>
      <Box sx={{ padding: '1rem' }}>{handleRenderDisplay()}</Box>
      <br />
      <AppPagination
        defaultCurrent={1}
        defaultPageSize={pageSize}
        total={total}
        onPageChange={(pageNumber) => {
          setPageNum(pageNumber);
        }}
      />

      {dialogQuarters && (
        <FacilityReportQuarters
          open={dialogQuarters}
          onClose={() => setDialogQuarters(false)}
          record={row}
        />
      )}
    </Box>
  );
});

export default FacilityScreen;
