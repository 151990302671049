import { Close, Comment } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Tooltip,
  Typography,
  Alert,
} from '@mui/material';
import { ApprovalRounded, AttachFile, Attachment, MoreHoriz, Receipt } from '@mui/icons-material';
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Upload,
  DatePicker,
  Select,
  Space,
  Table,
  Divider,
  Popover,
  Empty,
} from 'antd';
import CloseIcon from '../../../common/CloseIcon';
import {
  LoadingOutlined,
  MessageFilled,
  MessageOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL, getInvoices, getPayments, getToken } from '../../../../helpers/api';
import Button from '../../../common/Button';
import { openNotification } from '../../../../helpers/notification';
import { formatDate } from '../../../../helpers/utils';
import AppPagination from '../../../common/AppPagination';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import MUIDataTable from 'mui-datatables';

export default function FacilityPaymentList({ props, onClose, onAddPayments, invoiceId }) {
  const { userCategory, userId, history } = props;
  const facilityIdProps = history.location.pathname?.split('/')[3];
  const queryParam = history.location?.search;
  const searchParams = new URLSearchParams(queryParam);
  const invoiceIdProps = searchParams.get('invoiceId');

  const initFacility = { facilityId: '', facilityName: '' };
  const initInvoice = { invoiceId: '', invoiceTitle: '' };

  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceSelected, setInvoiceSelected] = useState(initInvoice);

  const [facilityId, setFacilityId] = useState(facilityIdProps ?? '');
  // const [invoiceId, setInvoiceId] = useState(invoiceIdProps ?? '');

  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    fetchPayments();
  }, [facilityId, invoiceId]);

  const fetchPayments = () => {
    setLoading(true);
    getPayments(facilityId, '', invoiceId, '', '', '', '', pageNum, pageSize)
      .then((res) => {
        const responseData = res?.result?.data;
        setPayments(responseData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setInvoiceLoading(true);
    getInvoices('', facilityId, '', '', 1, 10000)
      .then((res) => {
        const responseData = res.result.data;
        const openInvoices = responseData?.filter((x) => x?.invoiceStatus !== 'CLOSED');
        setInvoiceData(openInvoices);

        const findInvoice = responseData?.find((x) => x?.invoiceId === invoiceId);
        if (findInvoice) {
          setInvoiceSelected(findInvoice);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setInvoiceLoading(false));
  }, [facilityId, invoiceId]);

  // useEffect(() => {
  //   setFacilityLoading(true);
  //   const url = `${baseURL}/TelerikReports/GetFacilityList/${userId}`;
  //   axios
  //     .get(url, {
  //       method: 'get',
  //     })
  //     .then((res) => {
  //       const responseData = res?.data;
  //       setFacilityData(responseData);

  //       const findFacility = responseData?.find((x) => x?.facilityId === facilityId);
  //       if (findFacility) {
  //         setFacilitySelected(findFacility);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('error', err);
  //     })
  //     .finally(() => setFacilityLoading(false));
  // }, []);

  const handleContentRendering = () => {
    if (loading) {
      return (
        <Box sx={{ padding: '1rem 0' }}>
          <LoaderSkeleton />
        </Box>
      );
    } else {
      if (payments?.length < 1) {
        return (
          <Box sx={{ padding: '3rem' }}>
            <Empty description="Nothing here." />
          </Box>
        );
      } else {
        return renderContent();
      }
    }
  };

  const renderContent = () => {
    const columns = [
      {
        name: 'approvalDate',
        label: 'Paid Date',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paymentDate = payments[index]?.paymentDate;
            return (
              <Typography variant="body2">
                {new Date(paymentDate).toLocaleDateString('en-NG', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Typography>
            );
          },
        },
      },
      {
        name: 'amount',
        label: 'Expected',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const amount = payments[index]?.amount;
            return (
              <Typography variant="body2" sx={{}}>
                {Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount)}
              </Typography>
            );
          },
        },
      },
      {
        name: 'amountPaid',
        label: 'Paid',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paid = payments[index]?.amountPaid;
            const receipt = payments[index]?.paymentReceipt;
            return (
              <Stack direction={'flex'} spacing={0.5} alignItems={'center'}>
                <Typography variant="body2" sx={{}}>
                  {Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(paid)}
                </Typography>
                {receipt && (
                  <Tooltip title="View receipt">
                    <IconButton
                      size="small"
                      onClick={() => {
                        window.open(receipt);
                      }}
                    >
                      <AttachFile fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
      },
      // {
      //   name: 'facilityName',
      //   label: 'Facility',
      //   options: {
      //     customHeadLabelRender: (a) => {
      //       return (
      //         <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      //           {a.label}
      //         </Typography>
      //       );
      //     },
      //     customBodyRenderLite: (index) => {
      //       const facility = payments[index]?.facilityName;
      //       return (
      //         <Typography
      //           variant="body2"
      //           sx={{
      //             width: '150px',
      //             overflow: 'hidden',
      //             textOverflow: 'ellipsis',
      //             display: '-webkit-box',
      //             WebkitBoxOrient: 'vertical',
      //             WebkitLineClamp: 1,
      //           }}
      //         >
      //           {facility}
      //         </Typography>
      //       );
      //     },
      //   },
      // },

      // {
      //   name: 'invoiceTitle',
      //   label: 'Invoice',
      //   options: {
      //     customHeadLabelRender: (a) => {
      //       return (
      //         <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      //           {a.label}
      //         </Typography>
      //       );
      //     },
      //     customBodyRenderLite: (index) => {
      //       const title = payments[index]?.reportTitle;
      //       return (
      //         <Typography
      //           variant="body2"
      //           sx={
      //             {
      //               //   width: '150px',
      //               //   overflow: 'hidden',
      //               //   textOverflow: 'ellipsis',
      //               //   display: '-webkit-box',
      //               //   WebkitBoxOrient: 'vertical',
      //               //   WebkitLineClamp: 1,
      //             }
      //           }
      //         >
      //           {title}
      //         </Typography>
      //       );
      //     },
      //   },
      // },
      {
        name: 'invoiceStatus',
        label: 'Invoice Status',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            let color = '';

            const invoiceStatus = payments[index]?.invoiceStatus;
            let displayTxt = invoiceStatus;

            if (invoiceStatus === 'OPEN') {
              color = 'error';
              displayTxt = 'Open';
            }
            if (invoiceStatus === 'ONGOING') {
              color = 'warning';
              displayTxt = 'Ongoing';
            }
            if (invoiceStatus === 'CLOSED') {
              color = 'success';
              displayTxt = 'Closed';
            }
            return <Chip label={displayTxt} color={color} size="medium" sx={{ width: '80px' }} />;
          },
        },
      },
      {
        name: '',
        label: 'Channel',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const paymentMethod = payments[index]?.paymentMethod;
            const paymentGateway = payments[index]?.paymentGateway;
            return (
              <Typography variant="body2">
                {paymentMethod} {paymentGateway && <span> | {paymentGateway}</span>}
              </Typography>
            );
          },
        },
      },
      {
        name: 'approver',
        label: 'Approver',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const approver = payments[index]?.approver;
            const comment = payments[index]?.approverComment;
            const approvalDate = payments[index]?.approvalDate;
            return (
              <Stack direction={'row'} spacing={0.5} alignItems={'start'}>
                <Box>
                  <Typography variant="body2">{approver}</Typography>
                  <Typography variant="caption" color="text.disabled">
                    {new Date(approvalDate).toLocaleDateString('en-NG', { dateStyle: 'medium' })}
                  </Typography>
                </Box>

                {comment && (
                  <Tooltip title={comment}>
                    <IconButton size="small">
                      <Comment color="info" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
      },
    ];

    return (
      <MUIDataTable
        title=""
        data={payments}
        columns={columns}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          tableBodyHeight: '40vh',
          setRowProps: (ev, dataIndex) => {
            return {
              style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
            };
          },
        }}
      />
    );
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DialogTitle variant="body1" sx={{ fontWeight: 600 }}>
          Payment history
        </DialogTitle>
        <Box
          sx={{
            ml: 'auto',
            padding: '1rem 1rem 0 1rem',
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          {invoiceSelected?.invoiceStatus !== 'CLOSED' && (
            <Chip
              label="Add Payment"
              color="info"
              variant="outlined"
              onClick={() => onAddPayments(invoiceId)}
            />
          )}

          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {invoiceSelected && (
          <Alert severity="info" sx={{ marginBottom: '10px' }}>
            {invoiceSelected?.invoiceTitle} payments for {invoiceSelected?.facilityName}
          </Alert>
        )}
        {invoiceSelected && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Chip
              label={`Outstanding: ${Intl.NumberFormat('en-NG', {
                style: 'currency',
                currency: 'NGN',
              }).format(invoiceSelected?.outstandingAmount)}`}
              color="error"
            />

            <Chip
              label={`Paid: ${Intl.NumberFormat('en-NG', {
                style: 'currency',
                currency: 'NGN',
              }).format(invoiceSelected?.totalAmountPaid)}`}
              color="success"
            />
          </Box>
        )}
        <br />
        {handleContentRendering()}

        <br />
        <AppPagination
          defaultCurrent={1}
          defaultPageSize={pageSize}
          total={total}
          onPageChange={(pageNumber) => {
            setPageNum(pageNumber);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
