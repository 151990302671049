import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import FacilityResultScreen from '../components/screens/facilities2/FacilityResultScreen';

export default function FacilityResults(props) {
  return (
    <Layout headerTitle="Results">
      <PageContainer id="facility_results">
        <FacilityResultScreen props={props} />
      </PageContainer>
    </Layout>
  );
}
