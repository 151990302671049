import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../store/AppContext';
import { UserContext } from '../../store/UserContext';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';
import { Logout, Person2 } from '@mui/icons-material';

export default function Navuser({ className }) {
  const {
    state: { auth },
  } = useContext(AppContext);
  const { dispatch } = useContext(AppContext);
  const { userData, getUserData } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        {/* <Chip label={userData?.role} /> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              opacity: 0.8,
              cursor: 'pointer',
            },
          }}
          onClick={handleClick}
        >
          <IconButton onClick={handleClick} size="small">
            <Avatar sx={{ height: '35px', width: '35px' }}>
              <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                {userData?.firstname?.charAt(0)}
                {userData?.lastname?.charAt(0)}
              </Typography>
            </Avatar>
          </IconButton>
          <Box>
            <Typography color="textPrimary">
              {userData?.firstname} {userData?.lastname}
            </Typography>
            <Typography color="textDisabled" sx={{ fontSize: '0.65rem', fontWeight: 600 }}>
              {userData?.userCategory}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '10px',
              width: '250px',
            },
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <br />
          <Avatar sx={{ height: 70, width: 70 }}>
            <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '2rem' }}>
              {userData?.firstname?.charAt(0)}
              {userData?.lastname?.charAt(0)}
            </Typography>
          </Avatar>
          <Box sx={{ textAlign: 'center' }}>
            <Typography color="textPrimary">
              {userData?.firstname} {userData?.lastname}
            </Typography>
            <Typography
              component={'div'}
              color="textSecondary"
              sx={{ textAlign: 'center', marginTop: '5px' }}
              variant="caption"
            >
              Your Role:
            </Typography>
            <Typography
              component={'div'}
              color="textSecondary"
              sx={{ textAlign: 'center', fontWeight: 800 }}
              variant="caption"
            >
              {userData?.role}
            </Typography>
          </Box>
        </Box>
        <br />
        <Divider />
        <ListItemButton sx={{ padding: '0.5rem 1rem' }}>
          <ListItemIcon>
            <Person2 />
          </ListItemIcon>
          <ListItemText primary={<Link to="/settings/profile">Profile</Link>} />
        </ListItemButton>
        <Divider />
        <ListItemButton sx={{ padding: '0.5rem   1rem' }}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to="/" onClick={logout}>
                Log Out
              </Link>
            }
          />
        </ListItemButton>
      </Menu>
    </Box>
  );
}
