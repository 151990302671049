import Layout from '../components/common/Layout';
import PageContainer from '../components/common/PageContainer';
import PaymentScreen from '../components/screens/payments/PaymentScreen';

export default function Payment(props) {
  return (
    <Layout headerTitle="Payment">
      <PageContainer id="payment">
        <PaymentScreen props={props} />
      </PageContainer>
    </Layout>
  );
}
