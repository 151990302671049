import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getFacilityReport } from '../../../helpers/api';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import { Empty } from 'antd';
import StandardResultMeasurement from '../facilities2/facility-results/StandardResultMeasurement';
import ResultViewMeasurements from './result-view/ResultViewMeasurements';
import { userCats } from '../../../helpers/appstate';
import Notfound from '../../../pages/notfound';

const ResultViewScreen = withRouter(({ history, props }) => {
  const { userCategory, userPermission } = props;

  const facilityId = history?.location?.pathname?.split('/')[3];
  const reportId = history?.location?.pathname?.split('/')[4];
  const quarter = history?.location?.pathname?.split('/')[5];
  const year = history?.location?.pathname?.split('/')[6];

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [quarters, setQuarters] = useState([]);

  console.log('facilityId', facilityId);
  console.log('reportId', reportId);
  console.log('quarter', quarter);
  console.log('year', year);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getFacilityReport(facilityId, quarter, year)
      .then((response) => {
        const responseData = response?.result?.data;
        setData(responseData);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const handleContentRendering = () => {
    if (loading) {
      return (
        <Box padding="1rem">
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </Box>
      );
    } else {
      if (data?.length < 1) {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Empty />
            </Box>
          </Box>
        );
      } else {
        return <ResultViewMeasurements report={data} />;
      }
    }
  };

  return (
    <Box>
      {userCategory === userCats.CONSULTANT && !data?.isVisible ? (
        <Box>
          <Notfound props={props} />
        </Box>
      ) : (
        <Box padding="1rem 1.5rem">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs sx={{ flexGrow: 1 }}>
              <Link to="/results">
                <Typography variant="body2" color="info.main">
                  Results
                </Typography>
              </Link>
              {/* <Link to={`/facilities/${facilityId}/quarters`}>
        <Typography variant="body2" color="info.main">
          Quarters
        </Typography>
      </Link> */}
              <Typography variant="body2" color="textSecondary">
                {data?.reportTitle}
              </Typography>
            </Breadcrumbs>
          </Box>
          <br />
          <Box>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              {data?.facility}
            </Typography>
            {/* {data?.reportStatus === reportStatuses.PUBLISHED && (
                <Typography color="warning">Published results cannot be editted</Typography>
              )} */}
          </Box>
          <br />
          <Box>{handleContentRendering()}</Box>
        </Box>
      )}
    </Box>
  );
});

export default ResultViewScreen;
