import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { openNotification } from '../../../../helpers/notification';
import { toggleQaqcReportVisibilityApi } from '../../../../helpers/api';

export default function LockUnlockResult({ open, title, onClose, report }) {
  const [loading, setLoading] = useState(false);

  const handleLockUnlock = () => {
    setLoading(true);
    const payload = [report?.reportId];
    toggleQaqcReportVisibilityApi(payload)
      .then((response) => {
        openNotification({
          type: 'success',
          message: 'Action was successfull',
        });

        setTimeout(() => {
          onClose();
        }, 1000);
        onClose();
      })
      .catch((error) => {
        console.log(error);
        const errMsg = error.data?.result?.status?.desc;
        return openNotification({
          type: 'error',
          message: !errMsg ? 'Something went wrong. Please try again!' : errMsg,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '1rem' }}>
        <DialogTitle variant="body2" sx={{ fontWeight: 600 }}>
          {title}
        </DialogTitle>
        <Typography sx={{ marginLeft: 'auto', padding: '0 1rem' }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Typography>
      </Box>
      <DialogContent>
        <Alert severity="info">
          {report?.isVisible
            ? `Locking ${report?.reportTitle} results for ${report?.facility}`
            : `Unlocking ${report?.reportTitle} results for ${report?.facility}`}
        </Alert>
        <br />
        <Typography variant="body2">
          {report?.isVisible
            ? `If this action is completed, the designated faciliy will not be able to see the results that were collected for QAQC`
            : `If this action is completed, the designated faciliy will be able to see the results that were collected for QAQC`}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 1.5rem 2rem 1.5rem' }}>
        <Button
          type="button"
          size="medium"
          variant="contained"
          sx={{ textTransform: 'none', borderRadius: '30px', width: 'fit-width' }}
          onClick={handleLockUnlock}
          startIcon={loading && <CircularProgress size={20} />}
          disabled={loading}
        >
          {report?.isVisible ? 'Lock' : 'Unlock'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
