import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import ResultViewScreen from '../components/screens/result/ResultViewScreen';
//import ResultScreen from '../components/screens/result/ResultScreen';

export default function ResultView(props) {
  return (
    <Layout headerTitle={'results'}>
      <PageContainer id="results">
        <ResultViewScreen props={props} id="results" />
      </PageContainer>
    </Layout>
  );
}
