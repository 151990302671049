import { Box, Breadcrumbs, Chip, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { generateFacilityReport, getFacilityReport } from '../../../helpers/api';
import StandardResultMeasurement from './facility-results/StandardResultMeasurement';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import { Empty } from 'antd';
import Notfound from '../../../pages/notfound';
import { openNotification } from '../../../helpers/notification';
import { reportStatuses, reportTypes, userCats } from '../../../helpers/appstate';

const FacilityResultScreen = withRouter(({ history, props }) => {
  const { userCategory, userPermission } = props;

  const facilityId = history?.location?.pathname?.split('/')[2];
  const reportId = history?.location?.pathname?.split('/')[5];
  const quarter = history?.location?.pathname?.split('/')[6];
  const year = history?.location?.pathname?.split('/')[7];

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [quarters, setQuarters] = useState([]);

  // useEffect(() => {
  //   fetchData();
  // }, [quarter]);

  useEffect(() => {
    fetchQuarters();
  }, [quarter]);

  // const fetchData = () => {
  //   setLoading(true);
  //   getFacilityReport(facilityId, quarter, year)
  //     .then((response) => {
  //       const responseData = response?.result?.data;
  //       setData(responseData);
  //     })
  //     .catch((error) => {})
  //     .finally(() => setLoading(false));
  // };

  const fetchQuarters = () => {
    setLoading(true);
    generateFacilityReport(facilityId, year)
      .then((response) => {
        const responseData = response?.result?.data;
        const current = responseData?.find((x) => x.reportId === reportId);

        setData(current);
        setQuarters(responseData);
      })
      .catch((error) => {
        openNotification({
          type: 'error',
          message: error,
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSelectQuarter = (quarterItem) => {
    const newUrl = `/facilities/${facilityId}/quarters/result/${reportId}/${quarterItem}/${year}`;
    history.push(newUrl);
  };

  const renderDisabledQuarter = (item) => {
    if (userCategory === userCats.EDM && item?.reportType === reportTypes.NORMAL) {
      return true;
    } else if (
      userCategory === userCats.CONSULTANT &&
      item?.reportType === reportTypes.QAQC &&
      !item?.isVisible
    )
      return true;
    else return false;
  };

  const handleContentRendering = () => {
    if (loading) {
      return (
        <Box padding="1rem">
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </Box>
      );
    } else {
      if (data?.length < 1) {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Empty />
            </Box>
          </Box>
        );
      } else {
        return <StandardResultMeasurement report={data} />;
      }
    }
  };

  return (
    <Box>
      {userCategory === userCats.CONSULTANT && !data?.isVisible ? (
        <Box>
          <Notfound props={props} />
        </Box>
      ) : (
        <Box padding="1rem 1.5rem">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs sx={{ flexGrow: 1 }}>
              <Link to="/facilities">
                <Typography variant="body2" color="info.main">
                  Facilities
                </Typography>
              </Link>
              <Link to={`/facilities/${facilityId}/quarters`}>
                <Typography variant="body2" color="info.main">
                  Quarters
                </Typography>
              </Link>
              <Typography variant="body2" color="textSecondary">
                {data?.reportTitle}
              </Typography>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Chip
                label={data?.reportStatus}
                color={data?.reportStatus === reportStatuses.PUBLISHED ? 'success' : 'draft'}
              />
              <Select value={quarter} size="small">
                {quarters?.map((item) => (
                  <MenuItem
                    key={item?.reportId}
                    value={item?.reportQuarter}
                    selected={quarter === item?.reportQuarter}
                    onClick={() => handleSelectQuarter(item?.reportQuarter)}
                    disabled={renderDisabledQuarter(item)}
                  >
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                      <Chip
                        label={item?.reportType}
                        size="small"
                        color={item?.reportType === reportTypes.QAQC ? 'warning' : 'info'}
                        sx={{ marginRight: '0.5rem', fontSize: '0.6rem', width: '60px' }}
                      />
                      <Typography variant="body2">Quarter {item?.reportQuarter}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              {data?.facility}
            </Typography>
            {data?.reportStatus === reportStatuses.PUBLISHED && (
              <Typography color="warning">Published results cannot be editted</Typography>
            )}
          </Box>
          <br />
          <Box>{handleContentRendering()}</Box>
        </Box>
      )}
    </Box>
  );
});

export default FacilityResultScreen;
